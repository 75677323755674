import {
  collection,
  onSnapshot,
  query,
  setDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";

import { db } from "@/firebase";

const state = {
  firestoreListener: null,
  isLoading: true,
  list: [],
};

const actions = {
  attachUsersOnSnapshot({ state }) {
    return new Promise((resolve, reject) => {
      if (state.firestoreListener) {
        resolve(state.list);
        return;
      }
      state.isLoading = true;
      // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
      const q = query(collection(db, "users"));
      state.firestoreListener = onSnapshot(
        q,
        { includeMetadataChanges: true },
        (snapshot) => {
          state.list = [];

          snapshot.docs.forEach((doc) => {
            if (doc.exists) {
              state.list.push({ ...doc.data(), id: doc.id });
            }
          });
          state.isLoading = false;
          resolve(state.list);
        },
        (error) => {
          console.log(error.message);
          reject(error);
        }
      );
    });
  },

  updateUser({ commit }, { id, user }) {
    const docRef = doc(db, "users", id);
    setDoc(docRef, user, { merge: true })
      .then(() => {
        commit(
          "showSnackbar",
          {
            message: "Benutzer:in wurde erfolgreich aktualisiert",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((err) => {
        commit(
          "showSnackbar",
          { message: err.message, color: "error" },
          { root: true }
        );
      });
  },

  deleteUser({ commit }, { id }) {
    const docRef = doc(db, "users", id);
    deleteDoc(docRef)
      .then(() => {
        commit(
          "showSnackbar",
          {
            message: "Benutzer:in wurde erfolgreich gelöscht",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((err) => {
        commit(
          "showSnackbar",
          { message: err.message, color: "error" },
          { root: true }
        );
      });
  },
};

export default { state, actions };
