/* eslint-disable no-unused-vars, no-console */
import Vue from "vue";

import {
  collection,
  getDocs,
  query,
  doc,
  setDoc,
  addDoc,
  startAfter,
  deleteDoc,
  where,
  limit,
  onSnapshot,
  getDocsFromCache,
  enableIndexedDbPersistence,
} from "firebase/firestore";

import { db } from "@/firebase";

import { getFirebaseCollectionsArray } from "../helper/helper";

const state = {
  objects: [],
  firestoreListener: null,
  isLoading: false,
  selectedObject: {},
  searchTerm: "",
  isSearching: false,
  searchResult: [],
  isSelectingObject: false,
};

const getters = {};

const mutations = {
  setSelectedObject(state, object) {
    state.selectedObject = object;
  },

  setObjects(state, objects) {
    Vue.set(state, "objects", objects);
  },
};

const actions = {
  async attachObjectsOnSnapshotOld({ state, rootGetters }) {
    return new Promise((resolve, reject) => {
      if (state.firestoreListener) {
        resolve(state.objects);
        return;
      }

      state.isLoading = true;
      const q = query(
        collection(db, "objects3"),
        where("hauptKategorie", "in", rootGetters.writePermissions)
      );
      state.firestoreListener = onSnapshot(
        q,
        { includeMetadataChanges: true },
        (snapshot) => {
          state.objects = [];
          snapshot.docs.forEach((doc) => {
            if (doc.exists) {
              state.objects.push({ ...doc.data(), docid: doc.id });
            }
          });
          state.isLoading = false;
          resolve(state.objects);
        },
        (error) => {
          console.log(error.message);
          reject(error);
        }
      );
    });
  },

  async attachObjectsOnSnapshotNew({ state, commit, rootGetters }) {
    //const arr = getFirebaseCollectionsArray(rootGetters.readPermissions);
    //console.log("firebase collection Array", arr);
    //if (arr.length) {
    //  Promise.all(arr.map((obj) => onSnapshot(query(collection(db, obj)))))

    //    // merge the results
    //    .then((promiseResults) => {
    //      const mergedData = [];
    //      promiseResults.forEach((snapshot) => {
    //        snapshot.docs.forEach((doc) => mergedData.push(doc.data()));
    //      });
    //      return mergedData;
    //    })

    //    // sort the results
    //    .then((mergedData) =>
    //      mergedData.sort((a, b) => a.timestamp - b.timestamp)
    //    )

    //    // use the results
    //    .then((sortedData) => {
    //      // do something with data here
    //      console.log("SORTEDDATA", sortedData);
    //    })

    //    // log any errors
    //    .catch((e) => console.error(e));
    //}

    state.isLoading = true;
    let combinedArray = [];

    // Function to create snapshot listener for each collection
    const createSnapshotListener = (collectionPath) => {
      const q = query(collection(db, collectionPath));
      onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
        updateCombinedArray(
          collectionPath,
          snapshot.docs.map((doc) => {
            return { docid: doc.id, ...doc.data() };
          })
        );
        handleCombinedData();
      });
    };
    const collectionData = {};

    const updateCombinedArray = (collectionPath, data) => {
      collectionData[collectionPath] = data;
      combinedArray = [].concat(...Object.values(collectionData));
    };

    // Loop through collection paths and create listeners
    getFirebaseCollectionsArray(rootGetters.writePermissions).forEach(
      (path) => {
        createSnapshotListener(path);
      }
    );

    // Function to handle combined data
    const handleCombinedData = () => {
      // Process the combined data as needed
      console.log("Combined Data:", combinedArray);
      commit("setObjects", combinedArray);
      state.isLoading = false;
    };
  },

  async attachObjectsOnSnapshot({ state, rootState, dispatch, rootGetters }) {
    state.isLoading = true;
    console.log("attachObjectsOnSnapshot");
    await dispatch("attachIconsOnSnapshot");
    const start = performance.now();

    const maxItems = 100;
    const objectsCollection = collection(db, "objects3");

    console.log(rootGetters.getUserReadRights);

    let querySnapshot = await getDocsFromCache(
      query(
        objectsCollection,
        where("mainCategory", "in", ["Stempelgeraete"]),
        limit(maxItems)
      )
    );

    while (!querySnapshot.empty) {
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          const objType = doc.data().objectType;
          let type = "";
          if (objType.indexOf("-") > 0) {
            type = objType.split(" - ")[1];
          } else {
            type = objType;
          }
          const icon = rootState.icons.icons.find((icon) => icon.id == type);
          let url;
          if (icon) {
            url = icon.iconURL;
          } else {
            // Default icon
            url = "";
          }

          state.objects.push({
            ...doc.data(),
            docid: doc.id,
            icon: url,
          });
        }
      });

      state.isLoading = state.objects.length < 150;
      querySnapshot = await getDocsFromCache(
        query(
          objectsCollection,
          where("mainCategory", "in", ["Stempelgeraete"]),
          startAfter(querySnapshot.docs[querySnapshot.docs.length - 1]),
          limit(maxItems)
        )
      );
    }

    const stop = performance.now();
    localStorage.setItem("objects:cache", new Date().toISOString());
    state.isLoading = false;
    console.log("attachObjectsOnSnapshot took " + (stop - start) + " ms");
  },
  async attachObjectsOnSnapshotLegacy({
    state,
    rootState,
    dispatch,
    rootGetters,
  }) {
    console.log("attachObjectsOnSnapshotLegacy");
    await dispatch("attachIconsOnSnapshot");
    return new Promise((resolve, reject) => {
      const start = performance.now();
      // if (state.firestoreListener) {
      //     resolve(state.objects);
      //     return;
      // }
      state.isLoading = true;

      const q = query(
        collection(db, "objects3"),
        where("mainCategory", "in", rootGetters.getUserReadRights),
        limit(100)
      );
      state.firestoreListener = onSnapshot(
        q,
        { includeMetadataChanges: true },
        (snapshot) => {
          state.objects = [];
          console.log("Snapshot ready", snapshot);

          snapshot.docs.forEach((doc) => {
            if (doc.exists) {
              const objType = doc.data().objectType;
              let type = "";
              if (objType.indexOf("-") > 0) {
                type = objType.split(" - ")[1];
              } else {
                type = objType;
              }
              const icon = rootState.icons.icons.find(
                (icon) => icon.id == type
              );
              let url;
              if (icon) {
                url = icon.iconURL;
              } else {
                // Default icon
                url =
                  "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd";
              }

              state.objects.push({
                ...doc.data(),
                docid: doc.id,
                icon: url,
              });
            }
          });

          const stop = performance.now();
          state.isLoading = false;
          console.log("attachObjectsOnSnapshot took " + (stop - start) + " ms");
          resolve(state.objects);
        },
        (error) => {
          console.log(error.message);
          reject(error);
        }
      );
    });

    // state.isLoading = true
    // try {
    //     const q = query(collection(db, 'objects3'), where('mainCategory', 'in', rootGetters.getUserReadRights))
    //     const querySnapshot = await getDocs(q);
    //     state.objects = []
    //     querySnapshot.forEach((doc) => {
    //         // const objType = doc.data().objectType
    //         // const type = objType.split(' - ')
    //         // const icon = rootState.icons.icons.find(icon => icon.id == type[1])
    //         // let url
    //         // if (icon) {
    //         //     url = icon.iconURL
    //         // } else {
    //         //     // Default icon
    //         //     url = 'https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd'
    //         // }
    //         const objType = doc.data().objectType
    //         let type = ''
    //         if (objType.indexOf('-') > 0) {
    //             type = objType.split(' - ')[1]
    //         } else {
    //             type = objType
    //         }
    //         const icon = rootState.icons.icons.find(icon => icon.id == type)
    //         let url
    //         if (icon) {
    //             url = icon.iconURL
    //         } else {
    //             // Default icon
    //             url = 'https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd'
    //         }

    //         state.objects.push({ ...doc.data(), docid: doc.id, icon: url });
    //     });
    // } catch (e) {
    //     console.log(e.message)
    // }
    // state.isLoading = false
    // console.log('OBJLENGTH', state.objects.length)
    // for (let i = 0; i < objectTypes.length; i++) {
    //     try {
    //         const q = query(collection(db, 'objects', objectTypes[i], 'objects'));
    //         const querySnapshot = await getDocs(q);
    //         querySnapshot.forEach((doc) => {
    //             const objType = doc.data().objectType
    //             const type = objType.split(' - ')
    //             const icon = rootState.icons.icons.find(icon => icon.id == type[1])
    //             let url
    //             if (icon) {
    //                 url = icon.iconURL
    //             } else {
    //                 // Default icon
    //                 url = 'https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd'
    //             }

    //             state.objects.push({ ...doc.data(), docid: doc.id, icon: url });
    //         });
    //     } catch (e) {
    //         console.log(e.message)
    //     }
    // }
    // state.isLoading = false
    // console.log('OBJLENGTH', state.objects.length)

    // return new Promise((resolve, reject) => {
    //     if (state.firestoreListener) {
    //         resolve(state.objects);
    //         return;
    //     }
    //     // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
    //     // objectTypes.forEach(type => {
    //     // for (const type of objectTypes) {
    //     // console.log(objectTypes)

    //     state.isLoading = true;
    //     const q = query(collection(db, 'objects', objectType, 'objects'));
    //     state.firestoreListener =
    //         onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {

    //             state.objects = []
    //             snapshot.docs.forEach((doc) => {
    //                 if (doc.exists) {
    //                     const objType = doc.data().objectType
    //                     const type = objType.split(' - ')
    //                     const icon = rootState.icons.icons.find(icon => icon.id == type[1])
    //                     let url
    //                     if (icon) {
    //                         url = icon.iconURL
    //                     } else {
    //                         // Default icon
    //                         url = 'https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd'
    //                     }

    //                     state.objects.push({ ...doc.data(), docid: doc.id, icon: url });
    //                 }
    //             });
    //             state.isLoading = false;
    //             resolve(state.objects);
    //         }, (error) => {
    //             console.log(error.message);
    //             reject(error);
    //         })
    //     // }
    //     // });

    // })
  },

  async initSelectedFilterState({ rootState, dispatch }) {
    console.log("initSelectedFilterState");
    if (typeof localStorage.filterValue !== "undefined") {
      const defaultFilterValue = JSON.parse(localStorage.filterValue);
      rootState.categories.selectedFilterValue = defaultFilterValue;
      dispatch("attachObjectsOnMultipleFilter", {
        selectedFilterValue: defaultFilterValue,
      });
    }
  },

  async updateFilterValue({ rootState, dispatch }, { filterValue }) {
    console.log("updateFilterValue", filterValue);
    if (rootState.categories.selectedFilterValue.includes(filterValue)) {
      const newVal = rootState.categories.selectedFilterValue.filter(
        (val) => val != filterValue
      );
      rootState.categories.selectedFilterValue = newVal;
      const newObjects = state.objects.filter(
        (obj) => obj.objectType != filterValue
      );
      state.objects = newObjects;
      // console.log('actual value', rootState.categories.selectedFilterValue)
      localStorage.setItem(
        "filterValue",
        JSON.stringify(rootState.categories.selectedFilterValue)
      );
    } else {
      rootState.categories.selectedFilterValue.push(filterValue);
      dispatch("attachObjectsOnSelectedFilter", { filterValue });
      // console.log('actual value', rootState.categories.selectedFilterValue)
      localStorage.setItem(
        "filterValue",
        JSON.stringify(rootState.categories.selectedFilterValue)
      );
    }
  },

  async attachObjectsOnMultipleFilter(
    { state, dispatch, rootState },
    { selectedFilterValue }
  ) {
    console.log("attachObjectsOnMultipleFilter");
    state.isLoading = true;
    await dispatch("attachIconsOnSnapshot");
    const data = [];
    try {
      await selectedFilterValue.reduce(
        async (promisedResult, currentFilterValue) => {
          const q = query(
            collection(db, "objects3"),
            where("objectType", "==", currentFilterValue)
          );
          const objectSnapshot = await getDocs(q);

          objectSnapshot.forEach((doc) => {
            const objType = doc.data().objectType;
            let type = "";
            if (objType.indexOf("-") > 0) {
              type = objType.split(" - ")[1];
            } else {
              type = objType;
            }
            const icon = rootState.icons.icons.find((icon) => icon.id == type);
            let url;
            if (icon) {
              url = icon.iconURL;
            } else {
              // Default icon
              url =
                "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd";
            }
            data.push({
              ...doc.data(),
              docid: doc.id,
              icon: url,
            });
          });
          const sum = await promisedResult;
          sum.push(data);
          return sum;
        },
        []
      );
      state.objects.push(...data);
    } catch (err) {
      console.error(err.message);
    }
    state.isLoading = false;
  },

  async attachObjectsOnSelectedFilter(
    { state, rootState, dispatch },
    { filterValue }
  ) {
    console.log("attachObjectsOnSelectedFilter");
    state.isLoading = true;
    await dispatch("attachIconsOnSnapshot");
    try {
      const q = query(
        collection(db, "objects3"),
        where("objectType", "==", filterValue)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const objType = doc.data().objectType;
        let type = "";
        if (objType.indexOf("-") > 0) {
          type = objType.split(" - ")[1];
        } else {
          type = objType;
        }
        const icon = rootState.icons.icons.find((icon) => icon.id == type);
        let url;
        if (icon) {
          url = icon.iconURL;
        } else {
          // Default icon
          url =
            "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd";
        }
        state.objects.push({ ...doc.data(), docid: doc.id, icon: url });
      });
    } catch (e) {
      console.log(e.message);
    }
    state.isLoading = false;
  },

  async attachObjectsWithReduce(
    { state, rootState, dispatch },
    { objectTypes }
  ) {
    console.log("attachObjectsWithReduce");
    state.isLoading = true;
    await dispatch("attachIconsOnSnapshot");
    if (state.objects.length > 0) {
      state.isLoading = false;
      return;
    }
    state.objects = [];
    try {
      const objects = await objectTypes.reduce(
        async (promisedResult, currentObjectType) => {
          const q = query(
            collection(db, "objects", currentObjectType, "objects")
          );
          const objectSnapshot = await getDocs(q);
          const data = [];
          objectSnapshot.forEach((doc) => {
            const objType = doc.data().objectType;
            let type = "";
            if (objType.indexOf("-") > 0) {
              type = objType.split(" - ")[1];
            } else {
              type = objType;
            }
            const icon = rootState.icons.icons.find((icon) => icon.id == type);
            let url;
            if (icon) {
              url = icon.iconURL;
            } else {
              // Default icon
              url =
                "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd";
            }
            data.push({
              ...doc.data(),
              docid: doc.id,
              collectionName: currentObjectType,
              icon: url,
            });
          });
          const sum = await promisedResult;
          sum.push(data);
          return sum;
        },
        []
      );
      state.objects = objects.flat();
    } catch (e) {
      console.log(e.message);
    }
    state.isLoading = false;
  },

  async searchObject({ state, rootState }, { keyword }) {
    console.log("searchObject");
    state.isSearching = true;
    try {
      const q = query(collection(db, "objects3"), where("label", ">", keyword));
      const querySnapshot = await getDocs(q);
      state.objects = [];
      querySnapshot.forEach((doc) => {
        // const objType = doc.data().objectType
        // const type = objType.split(' - ')
        // const icon = rootState.icons.icons.find(icon => icon.id == type[1])
        // let url
        // if (icon) {
        //     url = icon.iconURL
        // } else {
        //     // Default icon
        //     url = 'https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd'
        // }
        const objType = doc.data().objectType;
        let type = "";
        if (objType.indexOf("-") > 0) {
          type = objType.split(" - ")[1];
        } else {
          type = objType;
        }
        const icon = rootState.icons.icons.find((icon) => icon.id == type);
        let url;
        if (icon) {
          url = icon.iconURL;
        } else {
          // Default icon
          url =
            "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd";
        }

        state.objects.push({ ...doc.data(), docid: doc.id, icon: url });
      });
    } catch (e) {
      console.log(e.message);
    }
    state.isLoading = false;
  },

  async initAllOfflineObjects({
    state,
    rootState,
    rootGetters,
    dispatch,
    commit,
  }) {
    console.log("initAllOfflineObjects");
    state.isLoading = true;
    await dispatch("attachIconsOnSnapshot");
    const data = [];
    try {
      await rootGetters.getUserReadRights.reduce(
        async (promisedResult, currentFilterValue) => {
          const q = query(
            collection(db, "objects3"),
            where("mainCategory", "==", currentFilterValue)
          );
          const objectSnapshot = await getDocs(q);

          objectSnapshot.forEach((doc) => {
            const objType = doc.data().objectType;
            let type = "";
            if (objType.indexOf("-") > 0) {
              type = objType.split(" - ")[1];
            } else {
              type = objType;
            }
            const icon = rootState.icons.icons.find((icon) => icon.id == type);
            let url;
            if (icon) {
              url = icon.iconURL;
            } else {
              // Default icon
              url =
                "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd";
            }
            const newObject = {
              ...doc.data(),
              docid: doc.id,
              icon: url,
            };
            commit("addObject", newObject);
            data.push(newObject);
          });
          const sum = await promisedResult;
          sum.push(data);
          return sum;
        },
        []
      );
      state.objects.push(...data);
      dispatch("saveObjects");
    } catch (err) {
      console.error(err.message);
    } finally {
      state.isLoading = false;
    }
  },

  updateObject({ commit }, { docid, data }) {
    const docRef = doc(db, "objects3", docid);
    setDoc(docRef, data, { merge: true })
      .then(() => {
        commit(
          "showSnackbar",
          {
            message: "Das Objekt wurde erfolgreich aktualisiert",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((err) => {
        commit(
          "showSnackbar",
          { message: err.message, color: "error" },
          { root: true }
        );
      });
  },

  deleteObject({ commit }, { docid, firebaseCollectionPath }) {
    const docRef = doc(db, firebaseCollectionPath, docid);
    deleteDoc(docRef)
      .then(() => {
        commit(
          "showSnackbar",
          {
            message: "Das Objekt wurde erfolgreich gelöscht",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((err) => {
        commit(
          "showSnackbar",
          { message: err.message, color: "error" },
          { root: true }
        );
      });
  },

  createObject({ commit }, { data, firebaseCollectionPath }) {
    addDoc(collection(db, firebaseCollectionPath), data)
      .then(() => {
        commit(
          "showSnackbar",
          {
            message: "Das Objekt wurde erfolgreich erstellt",
            color: "success",
          },
          { root: true }
        );
      })
      .catch((err) => {
        commit(
          "showSnackbar",
          { message: err.message, color: "error" },
          { root: true }
        );
      });
  },

  async loadFirebaseAsync(context, { readPermissions }) {
    const arr = getFirebaseCollectionsArray(readPermissions);
    let allItems = [];
    if (arr.length) {
      Promise.all(arr.map((obj) => getDocs(collection(db, obj))))

        // merge the results
        .then((promiseResults) => {
          const mergedData = [];
          promiseResults.forEach((snapshot) => {
            snapshot.forEach((doc) => mergedData.push(doc.data()));
          });
          return mergedData;
        })

        // sort the results
        .then((mergedData) =>
          mergedData.sort((a, b) => a.timestamp - b.timestamp)
        )

        // use the results
        .then((sortedData) => {
          // do something with data here
          allItems = [...sortedData];
        })

        // log any errors
        .catch((e) => console.error(e));
    }
  },
};

export default { state, getters, mutations, actions };
