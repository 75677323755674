<template>
  <v-row>
    <v-text-field
      v-model="radiusValue"
      class="mt-0 pt-0"
      hide-details
      label="Radius (in Meter)"
      type="number"
      style="width: 40px"
    >
      <template v-slot:append>
        <!--<v-menu
          v-model="menu"
          top
          nudge-bottom="105"
          nudge-left="16"
          :close-on-content-click="false"
          class="mx-2"
        >
          <template v-slot:activator="{ on }">
            <div :style="swatchStyle" v-on="on" />
          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker
                v-model="color"
                flat
                mode="hexa"
                hide-mode-switch
              />
            </v-card-text>
          </v-card>
        </v-menu>-->
      </template>
    </v-text-field>
    <!--<v-btn class="ml-2" @click="resetRadius">Reset</v-btn>-->
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { debounce } from "lodash";

export default {
  data() {
    return {
      radiusValue: 0,
      color: "#0000FF19",
      menu: false,
    };
  },

  props: ["submenu"],

  computed: {
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },

  watch: {
    radiusValue: debounce(function (val) {
      if (val != 0) {
        const category = this.submenu.label ? this.submenu.label : this.submenu;
        this.$store.dispatch("setRadius", {
          category,
          radius: val,
        });
        //this.setObjectsCustomRadius({
        //  objecttype: this.submenu.value,
        //  radius: val,
        //});
      }
    }, 1000),

    color: debounce(function (val) {
      this.setObjectsRadiusColor({
        objecttype: this.submenu.value,
        radiusColor: val,
      });
    }, 1000),
  },

  methods: {
    resetRadius() {
      this.radiusValue = 0;
      this.color = "#0000FF19";
      this.resetObjectsCustomRadius({ objecttype: this.submenu.value });
    },

    ...mapActions([
      "setObjectsCustomRadius",
      "resetObjectsCustomRadius",
      "setObjectsRadiusColor",
    ]),
  },
};
</script>

<style></style>
