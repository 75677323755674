// import Map from '@/views/map/Map'

// let routes = [
//     {
//         path: '/',
//         name: 'Home',
//         icon: 'mdi-home',
//         right: 'all',
//         navParent: "",
//         component: Map,
//         navIgnore: false
//         //navIgnore true dann nicht angezeigt
//     },
// ];

// export default routes

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/home/Home.vue"),
  },
  {
    path: "/offline",
    name: "offline",
    component: () =>
      import(/* webpackChunkName: "offline-list" */ "../views/list/List2.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login/Login.vue"),
  },
  {
    path: "/map",
    name: "map",
    component: () =>
      import(/* webpackChunkName: "map-mobile" */ "../views/map/MapPerf.vue"),
  },
  {
    path: "/desktop",
    name: "desktop-map",
    component: () =>
      import(
        /* webpackChunkName: "map-desktop" */ "../views/map/MapDesktop.vue"
      ),
  },
  {
    path: "/route-planner",
    name: "route-planner",
    component: () =>
      import(/* webpackChunkName: "route-plan" */ "../views/RoutePlanner.vue"),
  },
  {
    path: "/manage",
    name: "manage",
    component: () =>
      import(/* webpackChunkName: "manage" */ "../views/admin/Manage.vue"),
  },
  {
    path: "/manage-user",
    name: "manage-user",
    component: () =>
      import(/* webpackChunkName: "manage" */ "../views/admin/ManageUsers.vue"),
  },
  {
    path: "/cookies",
    name: "cookies",
    component: () =>
      import(/* webpackChunkName: "cookies" */ "../views/Cookies.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
