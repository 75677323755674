const state = {
    dialog: false,
    title: '',
    content: '',
    status: ''
}

const mutations = {
    showDialog(state, { title = 'Default Title', content = 'Default content', status = 'error' }) {
        state.title = title
        state.content = content
        state.status = status
        state.dialog = true
    },

    closeDialog(state) {
        state.dialog = false
        state.title = ''
        state.content = ''
        state.status = ''
    }
}

export default { state, mutations }
