import ls from '../helper/localStorageService'

const state = {
    isListView: false
}

const mutations = {
    loadDefaultView(state) {
        const isListView = ls.checkStorage('isListView')
        if (isListView != null) {
            state.isListView = isListView
        } else {
            ls.saveToStorage('isListView', state.isListView)
        }
    },

    switchView(state) {
        state.isListView = !state.isListView
        ls.saveToStorage('isListView', state.isListView)
    },

    setListView(state, value) {
        state.isListView = value
        ls.saveToStorage('isListView', state.isListView)
    }
}

export default { state, mutations }
