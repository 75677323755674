<template>
  <v-card :class="classNames">
    <!-- <v-autocomplete
      v-model="select"
      dense
      cache-items
      return-object
      flat
      hide-no-data
      hide-details
      label="Suchen"
      solo-inverted
      append-icon="mdi-magnify"
      :items="$store.state.localObjects.objects"
      :item-text="itemText"
      :filter="filterData"
      @change="showOnMap"
    ></v-autocomplete> -->
    <v-autocomplete
      v-model="select"
      dense
      return-object
      flat
      hide-no-data
      hide-details
      label="Suchen"
      solo-inverted
      cache-items
      clearable
      @click:clear="resetSelect"
      ref="autocompleteRef"
      hide-selected
      append-icon="mdi-magnify"
      :loading="isLoading"
      :search-input.sync="search"
      :items="items"
      :item-text="itemText"
      @change="showOnMap"
    ></v-autocomplete>
    <div class="search" ref="search"></div>
  </v-card>
</template>

<script>
import { debounce } from "lodash";
import { mapState } from "vuex";

export default {
  data() {
    return {
      // select: { objectType: "" },
      select: null,
      items: [],
      search: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState("poi", ["allItems"]),
    classNames() {
      if (this.$vuetify.breakpoint.width < 540) {
        return "search-box";
      }
      return ''
    },
  },

  watch: {
    search: debounce(function (val) {
        if(val.length >= 3) {
            val && val !== this.select && this.querySelection(val);
        }
    }, 500),
  },

  methods: {
    resetSelect() {
      this.select = null;
      this.$store.commit("poi/setSingleItem", this.select);
      this.$refs.autocompleteRef.blur();
    },
    showOnMap() {
      if (this.select) {
        // this.$store.commit("setSelectedFilterValue", [this.select.objectType]);
        this.$store.commit("poi/setSingleItem", this.select);
        // this.$store.commit("setSelectedObject", this.select);
      }
      this.$refs.autocompleteRef.blur();
    },

    querySelection(search) {
      this.isLoading = true;
      this.items = this.allItems.filter((obj) => {
        return (
          obj.label?.toLocaleLowerCase()?.indexOf(search.toLocaleLowerCase()) >
            -1 ||
          obj.properties.Stationskennzeichen?.indexOf(
            search.toLocaleLowerCase()
          ) > -1 ||
          obj.properties.kv_kennzeichen
            ?.toLocaleLowerCase()
            ?.indexOf(search.toLocaleLowerCase()) > -1 ||
          obj.properties['Schlüsselbezeichnung']
            ?.toLocaleLowerCase()
            ?.indexOf(search.toLocaleLowerCase()) > -1
        );
      });
      this.isLoading = false;
    },

    itemText(item) {
      const stationsKennzeichen = item.properties.Stationskennzeichen
        ? item.properties.Stationskennzeichen + " - "
        : "";
      const kvKennzeichen = item.properties.kv_kennzeichen
        ? item.properties.kv_kennzeichen + " - "
        : "";
      const schaltstellenKennzeichen = item.properties.Schaltstellenkennzeichen
        ? item.properties.Schaltstellenkennzeichen + " - "
        : "";
      const schluesselBezeichnung = item.properties['Schlüsselbezeichnung']
        ? item.properties['Schlüsselbezeichnung'] + " - "
        : "";
      return `${stationsKennzeichen}${kvKennzeichen}${schaltstellenKennzeichen}${schluesselBezeichnung}${item.label} - ${item.objectType}`;
    },
  },
};
</script>

<style>
.v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: none;
}

.search-box {
    width: 72%;
}

</style>
