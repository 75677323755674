// New store for data
/* no-console */
import Vue from "vue";
import Vuex from "vuex";
import { openDB } from "idb";
Vue.use(Vuex);

import {
  collection,
  getDocs,
  //  query,
  //  startAfter,
  //  where,
  //  limit,
  disableNetwork,
  enableNetwork,
  CACHE_SIZE_UNLIMITED,
} from "firebase/firestore";

import { db } from "@/firebase";

import { getFirebaseCollectionsArray } from "../helper/helper";

const getDb = async () => {
  return await openDB("newLocalMapObjects", 1, {
    upgrade(db) {
      // Create an object store for your documents
      const store = db.createObjectStore("objects", { keyPath: "id" });
      // Create an index for the mainCategory property
      store.createIndex("byMainCategory", "mainCategory");
    },
  });
};

let worker = new Worker("./firebase-worker.js");
const handleVisibilityChange = async () => {
  if (document.visibilityState === "hidden") {
    await disableNetwork(db);
  } else {
    await enableNetwork(db);
  }
};

document.addEventListener("visibilitychange", handleVisibilityChange);

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
};

const LAST_SYNCED_LOCALSTOREAGE_KEY = "last-synced-from-firebase";

const transformObject = (doc) => {
  let data = {};
  if (typeof doc.data === "function") {
    data = doc.data();
  } else {
    data = doc.data;
  }

  let id = doc.id;
  if (data.properties && data.properties.Id) {
    id = data.properties.Id;
  }

  //  const objType = data.objectType;
  //  let type = "";
  //  if (objType.indexOf("-") > 0) {
  //    type = objType.split(" - ")[1];
  //  } else {
  //    type = objType;
  //  }
  //  const icon = availableIcons.find((icon) => icon.id == type);
  //  let url =
  //    "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd";
  //  if (icon) {
  //    url = icon.iconURL;
  //  }

  return {
    id,
    ...data,
    //icon: url,
  };
};

export default {
  namespaced: true,
  state: {
    initialLoad: false, // Will be true during first load
    restoring: false, // Will be true while restoring from cache
    loading: false,
    items: [],
    allItems: [],
    listener: null,
    singleItem: null,
    routes: [], // used to plan a route.
  },

  getters: {
    existsInRoutePlanner: (state) => (item) => {
      return state.routes.find((i) => i.id === item.id);
    },
  },
  mutations: {
    setItems(state, items) {
      state.items = items;
    },
    setSingleItem(state, item) {
      state.singleItem = item;
    },
    setAllItems(state, items) {
      state.allItems = items;
    },
    addToAllItems(state, item) {
      state.allItems.push(item);
    },
    removeFromAllItems(state, item) {
      state.items = state.items.filter((i) => i.docid !== item.docid);
      state.allItems = state.allItems.filter((i) => i.docid !== item.docid);
      if (state.singleItem && state.singleItem.docid === item.docid) {
        state.singleItem = null;
      }
    },
    updateInAllItems(state, item) {
      state.items = state.items.map((i) => {
        if (i.properties.Id === item.properties.Id) {
          return item;
        }
        return i;
      });
      state.allItems = state.allItems.map((i) => {
        if (i.properties.Id === item.properties.Id) {
          return item;
        }
        return i;
      });
      if (state.singleItem && state.singleItem.docid === item.docid) {
        state.singleItem = item;
      }
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setInitialLoad(state, v) {
      state.initialLoad = v;
    },
    setRestoring(state, v) {
      state.restoring = v;
    },
    setRoutes(state, routes) {
      state.routes = routes;
    },
    //addRoute(state, point) {
    //  if (state.routes.length === 10) {
    //    // TODO Schickere Fehlermeldung evtl. mit Toast
    //    alert("Limit an routen erreicht");
    //    return;
    //  }
    //  state.routes.push(point);
    //  localStorage.setItem("routes", JSON.stringify(state.routes));
    //},
    removeRoute(state, point) {
      state.routes = state.routes.filter((p) => p.id !== point.id);
      localStorage.setItem("routes", JSON.stringify(state.routes));
    },
    setStartpoint(state, point) {
      let routes = state.routes.filter((p) => p.id !== point.id);
      state.routes = [point, ...routes];
      localStorage.setItem("routes", JSON.stringify(state.routes));
    },
  },

  actions: {
    addRoute({ commit, state }, point) {
      if (state.routes.length === 9) {
        commit(
          "showDialog",
          {
            title: "Maximale Anzahl erreicht!",
            content: "Es können maximal 9 Objekte übergeben werden.",
            status: "error",
          },
          { root: true }
        );
        return;
      }
      state.routes.push(point);
      localStorage.setItem("routes", JSON.stringify(state.routes));
    },
    restoreRoutes({ commit }) {
      const routes = localStorage.getItem("routes");
      if (routes) {
        commit("setRoutes", JSON.parse(routes));
      }
    },
    resetRoutenplanner({ commit }) {
      commit("setRoutes", []);
      localStorage.removeItem("routes");
    },
    async clearCache({ dispatch }) {
      await dispatch("resetItems");
      await dispatch("getItems");
    },
    async resetItems({ commit }) {
      commit("setItems", []);
      localStorage.removeItem(LAST_SYNCED_LOCALSTOREAGE_KEY);
    },
    async findItems({ state, commit }, filter) {
      if (filter.length === 0) {
        commit("setItems", []);
        return;
      }
      const start = performance.now();
      const filteredItems = [];
      for (const item of state.allItems) {
        if (filter.includes(item.objectType)) {
          filteredItems.push(item);
        }
      }
      commit("setItems", filteredItems);

      const end = performance.now();
      console.log("FILTER", state.allItems.length);
      console.log(
        `findItems took: ${end - start}ms and found ${
          filteredItems.length
        } items`
      );
    },

    async restoreItems({ commit, dispatch }) {
      commit("setRestoring", true);

      const start = performance.now();
      const db = await getDb();
      const tx = db.transaction("objects", "readonly");
      const store = tx.objectStore("objects");
      const items = await store.getAll();
      await tx.complete;

      commit("setAllItems", items);
      const end = performance.now();
      console.log(
        `restoreItems took: ${end - start}ms and found ${items.length} items`
      );
      commit("setRestoring", false);
      dispatch("listenToItems");
    },

    async getItems({ dispatch }) {
      //  await dispatch("attachIconsOnSnapshot", null, { root: true });
      const lastFetched = localStorage.getItem(LAST_SYNCED_LOCALSTOREAGE_KEY);
      // NOTICE if you want to force loading from firebase, you can expire the lastFetched here
      if (lastFetched !== null) {
        // We are most likly up to date.
        dispatch("restoreItems");
        return;
      }
      await dispatch("resetItems");
      await dispatch("initialFetch");
    },

    listenToItems({ commit, rootGetters }) {
      console.log("Okay listening…");

      //  const availableIcons = rootState.icons.icons;
      worker.postMessage({
        type: "init foo",

        payload: {
          config: firebaseConfig,
          rights: rootGetters.readPermissions,
        },
      });
      worker.onmessage = async (event) => {
        const { type, payload } = event.data;
        if (["added", "modified", "removed"].includes(type)) {
          const localdb = await getDb();
          const tx = localdb.transaction("objects", "readwrite");
          const store = tx.objectStore("objects");
          const item = transformObject(payload);

          if (type === "added") {
            if (item.id) {
              await store.put(item);
            }
            commit("addToAllItems", item);
          }
          if (type === "modified") {
            if (item.id) {
              await store.put(item);
            }
            commit("updateInAllItems", item);
          }
          if (type === "removed") {
            store.delete(item);
            if (item.id) {
              await store.put(item);
            }
            commit("removeFromAllItems", item);
          }
          await tx.complete;
        }
      };
    },

    //async initialFetch({ commit, rootState, dispatch, rootGetters }) {
    //  const start = performance.now();
    //  commit("setLoading", true);
    //  commit("setInitialLoad", true);

    //  const allItems = [];
    //  const maxItems = 500;
    //  const objectsCollection = collection(db, "objects3");

    //  let querySnapshot = await getDocs(
    //    query(
    //      objectsCollection,
    //      where("hauptKategorie", "in", rootGetters.readPermissions),
    //      limit(maxItems)
    //    )
    //  );

    //  while (!querySnapshot.empty) {
    //    const newItems = querySnapshot.docs.map((doc) => {
    //      const availableIcons = rootState.icons.icons;
    //      return transformObject(doc, availableIcons);
    //    });

    //    allItems.push(newItems);

    //    const q = query(
    //      objectsCollection,
    //      where("hauptKategorie", "in", rootGetters.readPermissions),
    //      startAfter(querySnapshot.docs[querySnapshot.docs.length - 1]),
    //      limit(maxItems)
    //    );
    //    querySnapshot = await getDocs(q);
    //  }

    //  //const allItems = [];
    //  //const firestorePromises = [];
    //  //rootGetters.getUserReadRights.map((userRight) => {
    //  //    firestorePromises.push(getDocs(
    //  //        query(
    //  //            objectsCollection,
    //  //            where("mainCategory", "in", userRight)
    //  //        )
    //  //    ))
    //  //});

    //  //Promise.all(firestorePromises).then((docs) => {
    //  //    const newItems = docs.map((doc) => {
    //  //    const availableIcons = rootState.icons.icons;
    //  //    return transformObject(doc, availableIcons);
    //  //    });
    //  //    allItems.push(newItems);
    //  //});

    //  const localdb = await getDb();
    //  const tx = localdb.transaction("objects", "readwrite");
    //  const store = tx.objectStore("objects");

    //  for (const item of allItems.flat()) {
    //    if (item.id) {
    //      await store.put(item);
    //      await tx.complete;
    //    } else {
    //      console.log("Skipped because of missing id", item);
    //    }
    //  }

    //  commit("setLoading", false);
    //  commit("setInitialLoad", false);
    //  commit("setAllItems", allItems.flat());
    //  const stop = performance.now();
    //  console.log("poi/initialFetch took: ", stop - start);

    //  localStorage.setItem(LAST_SYNCED_LOCALSTOREAGE_KEY, new Date().getTime());
    //  dispatch("listenToItems");
    //},

    async initialFetch({ commit, dispatch, rootGetters }) {
      const start = performance.now();
      commit("setLoading", true);
      commit("setInitialLoad", true);

      const firestoreCollectionsPath = getFirebaseCollectionsArray(
        rootGetters.readPermissions
      );

      if (firestoreCollectionsPath.length) {
        const promiseResults = await Promise.all(
          firestoreCollectionsPath.map((obj) => getDocs(collection(db, obj)))
        );
        const mergedData = [];
        promiseResults.forEach((snapshot) => {
          snapshot.forEach((doc) =>
            mergedData.push({ id: doc.id, ...doc.data() })
          );
        });
        const sortedData = mergedData.sort((a, b) => a.timestamp - b.timestamp);
        const localdb = await getDb();
        const tx = localdb.transaction("objects", "readwrite");
        const store = tx.objectStore("objects");

        for (const item of sortedData) {
          if (item.id) {
            await store.put(item);
            await tx.complete;
          } else {
            console.log("Skipped because of missing id", item);
          }
        }

        commit("setAllItems", sortedData);
        commit("setLoading", false);
        commit("setInitialLoad", false);
        const stop = performance.now();
        console.log("poi/initialFetch took: ", stop - start);

        localStorage.setItem(
          LAST_SYNCED_LOCALSTOREAGE_KEY,
          new Date().getTime()
        );
        dispatch("listenToItems");
      }
    },
  },
};
