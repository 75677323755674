<template>
  <div>
    <div v-if="isOnMapPage">
      <v-toolbar
        absolute
        class="ma-4"
        style="border-radius: 2px"
        :width="toolbarWidth"
      >
        <v-progress-circular
          v-if="restoring"
          class="mr-2"
          indeterminate
          color="primary"
          size="25"
        ></v-progress-circular>
        <v-app-bar-nav-icon
          v-else
          @click="showNavigation = true"
        ></v-app-bar-nav-icon>
        <Search />
      </v-toolbar>

      <v-navigation-drawer
        v-model="showNavigation"
        :permanent="showNavigation"
        absolute
        width="320"
        temporary
      >
        <v-row class="mt-5 mx-5" align="center" justify="center">
          <v-col cols="10">
            <span>Karten NEW</span>
          </v-col>
          <v-col cols="2">
            <v-btn icon @click="showNavigation = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-switch
            inset
            label="Offline-Funktion"
            class="ml-3"
            :input-value="$store.state.settings.isListView"
            @change="switchView"
          ></v-switch>
        </v-row>
        <div class="mt-5 mx-5">
          <MapPanel />
        </div>
      </v-navigation-drawer>
    </div>
    <v-speed-dial
      v-model="speedDial"
      fixed
      left
      bottom
      direction="top"
      transition="slide-y-reverse-transition"
      v-if="!isMobile"
    >
      <template v-slot:activator>
        <v-btn v-model="speedDial" color="primary" dark fab value="SpeedDial">
          <v-icon v-if="speedDial"> mdi-close </v-icon>
          <v-icon v-else> mdi-information-variant </v-icon>
        </v-btn>
      </template>
      <v-btn
        href="https://www.new.de/impressum"
        elevation="2"
        small
        color="primary"
      >
        Impressum
      </v-btn>
      <v-btn :href="datenschutzUrl" elevation="2" small color="primary">
        Datenschutz
      </v-btn>
      <v-btn @click="openCookieDialog" elevation="2" small color="primary">
        Datenschutz-Einstellungen
      </v-btn>
    </v-speed-dial>
  </div>
</template>

<script>
import { mapState } from "vuex";
import MapPanel from "./MapPanel.vue";
import Search from "./Search.vue";

export default {
  components: {
    MapPanel,
    Search,
  },

  data() {
    return {
      drawer: false,
      speedDial: false,
      isListView: false,
      onLine: navigator.onLine,
      showNavigation: false,
    };
  },

  computed: {
    ...mapState("poi", ["restoring"]),
    toolbarWidth() {
      if (this.$vuetify.breakpoint.width < 540) {
        return "94%";
      } else {
        return "300";
      }
    },
    datenschutzUrl() {
      return `https://datenschutz.new.de/${process.env.VUE_APP_LOGIN_NEW_CLIENT_ID}`;
    },

    isOnMapPage() {
      return this.$route.name == "map" || this.$route.name == 'desktop-map';
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|MAPP/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
  },

  watch: {
    // onLine: {
    //   handler: async function (status) {
    //     if (status) {
    //       window.localStorage.setItem("isListView", false);
    //       await this.$store.dispatch("checkStorage");
    //       await this.$store.dispatch("loadDefaultFilterValue");
    //     } else {
    //       window.localStorage.setItem("isListView", true);
    //       this.$router.push("/");
    //     }
    //   },
    //   immediate: true,
    // },

    "$store.state.settings.isListView": {
      handler: function (status) {
        if (status) {
          this.$router.push('/offline')
        }
      },
      immediate: true,
    },
  },

  methods: {
    openCookieDialog() {
      loginNew.showCookieDialog();
    },
    onClickOutsideWithConditional() {},
    closeConditional() {},
    switchView() {
      this.$store.commit("switchView");
    },

    //updateOnlineStatus(e) {
    //  const { type } = e;
    //  this.onLine = type === "online";
    //},
  },

  mounted() {
    //window.addEventListener("online", this.updateOnlineStatus);
    //window.addEventListener("offline", this.updateOnlineStatus);

    // const loadDefault = window.localStorage.getItem("isListView");
    // if (loadDefault != null) {
    //   this.isListView = loadDefault === "true" ? true : false;
    // } else {
    //   this.isListView = false;
    //   window.localStorage.setItem("isListView", this.isListView);
    // }
  },

  beforeDestroy() {
    //window.removeEventListener("online", this.updateOnlineStatus);
    //window.removeEventListener("offline", this.updateOnlineStatus);
  },
};
</script>

<style>
.v-speed-dial__list {
  align-items: flex-start;
}
</style>
