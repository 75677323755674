const state = {
    snackbar: false,
    message: '',
    color: ''
}

const mutations = {
    showSnackbar(state, { message = 'Default message', color = 'success' }) {
        state.message = message
        state.color = color
        state.snackbar = true
    },

    closeSnackbar(state) {
        state.snackbar = false
        state.message = ''
        state.color = ''
    }
}

export default { state, mutations }
