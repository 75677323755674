import { collection, onSnapshot, query } from "firebase/firestore";

import { db } from "@/firebase";

const state = {
  firestoreListener: null,
  isLoading: true,
  list: [],
};

const actions = {
  attachMappUsersOnSnapshot({ state }) {
    return new Promise((resolve, reject) => {
      if (state.firestoreListener) {
        resolve(state.list);
        return;
      }
      state.isLoading = true;
      const q = query(collection(db, "mappUsers"));
      state.firestoreListener = onSnapshot(
        q,
        { includeMetadataChanges: true },
        (snapshot) => {
          state.list = [];

          snapshot.docs.forEach((doc) => {
            if (doc.exists) {
              state.list.push({ ...doc.data(), id: doc.id });
            }
          });
          state.isLoading = false;
          resolve(state.list);
        },
        (error) => {
          console.log(error.message);
          reject(error);
        }
      );
    });
  },
};

export default { state, actions };
