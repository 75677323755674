import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import store from "./store";
import router from "./router";

import './loginNew'

import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";

Vue.use(Vuex);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLEMAPS_API_KEY,
    libraries: "places",
  },
  autobindAllEvents: false,
});

Vue.component("GmapCluster", GmapCluster);

// import GmapVue, { components } from 'gmap-vue'

import "./registerServiceWorker";
// Vue.use(GmapVue, {
//   load: {
//     // [REQUIRED] This is the unique required value by Google Maps API
//     key: process.env.VUE_APP_GOOGLEMAPS_API_KEY,
//     // [OPTIONAL] This is required if you use the Autocomplete plugin
//     // OR: libraries: 'places,drawing'
//     // OR: libraries: 'places,drawing,visualization'
//     // (as you require)
//     libraries: 'places',
//   },
//   // [OPTIONAL] If you want to manually install components, e.g.
//   // import {GmapMarker} from 'gmap-vue/src/components/marker'
//   // Vue.component('GmapMarker', GmapMarker)
//   // then set installComponents to 'false'.
//   // If you want to automatically install all the components this property must be set to 'true':
//   installComponents: true,
// })

// Vue.component('GmapCluster', components.Cluster);


new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
