const getHauptKategorie = (category) => {
  switch (category) {
    case "Bezirksregelanlage":
    case "Erdgastankstelle":
    case "Kundenanlage":
    case "Kombianlage":
    case "Übergabestation":
      return "Gas";
    case "Stationen":
    case "Kabelverteiler":
    case "Schaltstellen":
    case "Umspannanlagen":
      return "Strom";
    default:
      return category;
  }
};

//const mappedObjects = {
//  stempelgeraete: {
//    values: ["Stempelgeraete"],
//    collections: ["objects/Stempelgeraete/objects"],
//  },
//  strom: {
//    category: "Strom",
//    menu: [
//      {
//        label: "Stationen",
//        layer: [
//          {
//            label: "Kreis Viersen",
//            layer: [
//              {
//                label: "Ortsnetzstation",
//                value: "Kreis Viersen - Ortsnetzstation",
//              },
//              {
//                label: "Kundenstation",
//                value: "Kreis Viersen - Kundenstation",
//              },
//              {
//                label: "Gemeinschaftsstation",
//                value: "Kreis Viersen - Gemeinschaftsstation",
//              },
//              {
//                label: "MS Schrank",
//                value: "Kreis Viersen - MS Schrank",
//              },
//              {
//                label: "Verteiler SA",
//                value: "Kreis Viersen - Verteiler SA",
//              },
//            ],
//            type: "checkbox",
//          },
//          {
//            label: "Kreis Heinsberg",
//            layer: [
//              {
//                label: "Ortsnetzstation",
//                value: "Kreis Heinsberg - Ortsnetzstation",
//              },
//              {
//                label: "Kundenstation",
//                value: "Kreis Heinsberg - Kundenstation",
//              },
//              {
//                label: "Gemeinschaftsstation",
//                value: "Kreis Heinsberg - Gemeinschaftsstation",
//              },
//              {
//                label: "MS Schrank",
//                value: "Kreis Heinsberg - MS Schrank",
//              },
//              {
//                label: "Verteiler SA",
//                value: "Kreis Heinsberg - Verteiler SA",
//              },
//            ],
//            type: "checkbox",
//          },
//          {
//            label: "Rhein Kreis Neuss",
//            layer: [
//              {
//                label: "Ortsnetzstation",
//                value: "Rhein Kreis Neuss - Ortsnetzstation",
//              },
//              {
//                label: "Kundenstation",
//                value: "Rhein Kreis Neuss - Kundenstation",
//              },
//              {
//                label: "Gemeinschaftsstation",
//                value: "Rhein Kreis Neuss - Gemeinschaftsstation",
//              },
//              {
//                label: "MS Schrank",
//                value: "Rhein Kreis Neuss - MS Schrank",
//              },
//              {
//                label: "Verteiler SA",
//                value: "Rhein Kreis Neuss - Verteiler SA",
//              },
//            ],
//            type: "checkbox",
//          },
//          {
//            label: "Stadt Mönchengladbach",
//            layer: [
//              {
//                label: "Ortsnetzstation",
//                value: "Stadt Mönchengladbach - Ortsnetzstation",
//              },
//              {
//                label: "Kundenstation",
//                value: "Stadt Mönchengladbach - Kundenstation",
//              },
//              {
//                label: "Gemeinschaftsstation",
//                value: "Stadt Mönchengladbach - Gemeinschaftsstation",
//              },
//              {
//                label: "MS Schrank",
//                value: "Stadt Mönchengladbach - MS Schrank",
//              },
//              {
//                label: "Verteiler SA",
//                value: "Stadt Mönchengladbach - Verteiler SA",
//              },
//            ],
//            type: "checkbox",
//          },
//        ],
//        type: "submenu",
//      },
//      {
//        label: "Umspannanlagen",
//        layer: [
//          { label: "Kreis Viersen", value: "Kreis Viersen - Umspannanlage" },
//          {
//            label: "Kreis Heinsberg",
//            value: "Kreis Heinsberg - Umspannanlage",
//          },
//          {
//            label: "Rhein Kreis Neuss",
//            value: "Rhein Kreis Neuss - Umspannanlage",
//          },
//          {
//            label: "Stadt Mönchengladbach",
//            value: "Stadt Mönchengladbach - Umspannanlage",
//          },
//        ],
//        type: "checkbox",
//      },
//      {
//        label: "Kabelverteiler",
//        layer: [
//          {
//            label: "Kreis Viersen",
//            layer: [
//              {
//                label: "Brüggen",
//                value: "Brüggen - NS Kabelverteiler",
//              },
//              {
//                label: "Krefeld",
//                value: "Krefeld - NS Kabelverteiler",
//              },
//              {
//                label: "Niederkrüchten",
//                value: "Niederkrüchten - NS Kabelverteiler",
//              },
//              {
//                label: "Schwalmtal",
//                value: "Schwalmtal - NS Kabelverteiler",
//              },
//              {
//                label: "Viersen",
//                value: "Viersen - NS Kabelverteiler",
//              },
//              {
//                label: "Tönisvorst",
//                value: "Tönisvorst - NS Kabelverteiler",
//              },
//            ],
//            type: "checkbox",
//          },
//          {
//            label: "Kreis Heinsberg",
//            layer: [
//              { label: "Erkelenz", value: "Erkelenz - NS Kabelverteiler" },
//              { label: "Gangelt", value: "Gangelt - NS Kabelverteiler" },
//              {
//                label: "Geilenkirchen",
//                value: "Geilenkirchen - NS Kabelverteiler",
//              },
//              { label: "Heinsberg", value: "Heinsberg - NS Kabelverteiler" },
//              {
//                label: "Hückelhoven",
//                value: "Hückelhoven - NS Kabelverteiler",
//              },
//              { label: "Selfkant", value: "Selfkant - NS Kabelverteiler" },
//              {
//                label: "Übach-Palenberg",
//                value: "Übach-Palenberg - NS Kabelverteiler",
//              },
//              { label: "Waldfeucht", value: "Waldfeucht - NS Kabelverteiler" },
//              { label: "Wassenberg", value: "Wassenberg - NS Kabelverteiler" },
//              { label: "Wegberg", value: "Wegberg - NS Kabelverteiler" },
//            ],
//            type: "checkbox",
//          },
//          {
//            label: "Rhein Kreis Neuss",
//            layer: [
//              {
//                label: "Grevenbroich",
//                value: "Grevenbroich - NS Kabelverteiler",
//              },
//              {
//                label: "Jüchen",
//                value: "Jüchen - NS Kabelverteiler",
//              },
//              {
//                label: "Korschenbroich",
//                value: "Korschenbroich - NS Kabelverteiler",
//              },
//              {
//                label: "Neuss",
//                value: "Neuss - NS Kabelverteiler",
//              },
//            ],
//            type: "checkbox",
//          },
//          {
//            label: "Stadt Mönchengladbach",
//            layer: [
//              {
//                label: "Nord",
//                value: "Mönchengladbach Nord - NS Kabelverteiler",
//              },
//              {
//                label: "Ost",
//                value: "Mönchengladbach Ost - NS Kabelverteiler",
//              },
//              {
//                label: "Süd",
//                value: "Mönchengladbach Süd - NS Kabelverteiler",
//              },
//              {
//                label: "West",
//                value: "Mönchengladbach West - NS Kabelverteiler",
//              },
//            ],
//            type: "checkbox",
//          },
//        ],
//        type: "submenu",
//      },
//      {
//        label: "Schaltstellen",
//        layer: [
//          { label: "Kreis Viersen", value: "Kreis Viersen - BL Schaltstelle" },
//          {
//            label: "Kreis Heinsberg",
//            value: "Kreis Heinsberg - BL Schaltstelle",
//          },
//          {
//            label: "Rhein Kreis Neuss",
//            value: "Rhein Kreis Neuss - BL Schaltstelle",
//          },
//          {
//            label: "Stadt Mönchengladbach",
//            value: "Stadt Mönchengladbach - BL Schaltstelle",
//          },
//        ],
//        type: "checkbox",
//      },
//    ],
//    values: ["Kabelverteiler", "Schaltstellen", "Stationen", "Umspannanlagen"],
//    collections: [
//      "objects5/Strom/Kabelverteiler",
//      "objects5/Strom/Schaltstellen",
//      "objects5/Strom/Stationen",
//      "objects5/Strom/Umspannanlagen",
//    ],
//  },
//  gas: {
//    category: "Gas",
//    menu: [
//      {
//        label: "Kombianlage",
//        layer: [
//          { label: "Kreis Viersen", value: "Kreis Viersen - Kombianlage" },
//          { label: "Kreis Heinsberg", value: "Kreis Heinsberg - Kombianlage" },
//          {
//            label: "Rhein Kreis Neuss",
//            value: "Rhein Kreis Neuss - Kombianlage",
//          },
//          {
//            label: "Stadt Mönchengladbach",
//            value: "Stadt Mönchengladbach - Kombianlage",
//          },
//        ],
//        type: "checkbox",
//      },
//      {
//        label: "Erdgastankstelle",
//        layer: [
//          { label: "Kreis Viersen", value: "Kreis Viersen - Erdgastankstelle" },
//          {
//            label: "Kreis Heinsberg",
//            value: "Kreis Heinsberg - Erdgastankstelle",
//          },
//          {
//            label: "Rhein Kreis Neuss",
//            value: "Rhein Kreis Neuss - Erdgastankstelle",
//          },
//          {
//            label: "Stadt Mönchengladbach",
//            value: "Stadt Mönchengladbach - Erdgastankstelle",
//          },
//        ],
//        type: "checkbox",
//      },
//      {
//        label: "Bezirksregelanlage",
//        layer: [
//          {
//            label: "Kreis Viersen",
//            value: "Kreis Viersen - Bezirksregelanlage",
//          },
//          {
//            label: "Kreis Heinsberg",
//            value: "Kreis Heinsberg - Bezirksregelanlage",
//          },
//          {
//            label: "Rhein Kreis Neuss",
//            value: "Rhein Kreis Neuss - Bezirksregelanlage",
//          },
//          {
//            label: "Stadt Mönchengladbach",
//            value: "Stadt Mönchengladbach - Bezirksregelanlage",
//          },
//        ],
//        type: "checkbox",
//      },
//      {
//        label: "Kundenanlage",
//        layer: [
//          {
//            label: "Kreis Viersen",
//            value: "Kreis Viersen - Übergabestation",
//          },
//          {
//            label: "Kreis Heinsberg",
//            value: "Kreis Heinsberg - Übergabestation",
//          },
//          {
//            label: "Rhein Kreis Neuss",
//            value: "Rhein Kreis Neuss - Übergabestation",
//          },
//          {
//            label: "Stadt Mönchengladbach",
//            value: "Stadt Mönchengladbach - Übergabestation",
//          },
//        ],
//        type: "checkbox",
//      },
//      {
//        label: "Übergabestation",
//        layer: [
//          {
//            label: "Kreis Viersen",
//            value: "Kreis Viersen - Übergabestation",
//          },
//          {
//            label: "Kreis Heinsberg",
//            value: "Kreis Heinsberg - Übergabestation",
//          },
//          {
//            label: "Rhein Kreis Neuss",
//            value: "Rhein Kreis Neuss - Übergabestation",
//          },
//          {
//            label: "Stadt Mönchengladbach",
//            value: "Stadt Mönchengladbach - Übergabestation",
//          },
//        ],
//        type: "checkbox",
//      },
//    ],
//    values: [
//      "Bezirksregelanlage",
//      "Erdgastankstelle",
//      "Kombianlage",
//      "Kundenanlage",
//      "Übergabestation",
//    ],
//    collections: [
//      "objects5/Gas/Bezirksregelanlage",
//      "objects5/Gas/Erdgastankstelle",
//      "objects5/Gas/Kombianlage",
//      "objects5/Gas/Kundenanlage",
//      "objects5/Gas/Übergabestation",
//    ],
//  },
//  "in betrieb": {
//    category: "Ladeinfrastruktur",
//    menu: [
//      {
//        label: "In Betrieb",
//        layer: [
//          { label: "Ladesäule", value: "In Betrieb - Ladesäule" },
//          { label: "Schnellladesäule", value: "In Betrieb - Schnellladesäule" },
//        ],
//        type: "checkbox",
//      },
//    ],
//    values: ["Ladesäule", "Schnellladesäule"],
//    collections: [
//      "objects5/In Betrieb/AC",
//      "objects5/In Betrieb/DC",
//      "objects5/In Betrieb/MIXED",
//    ],
//  },
//  "in planung": {
//    category: "Ladeinfrastruktur",
//    menu: [
//      {
//        label: "In Planung",
//        layer: [
//          { label: "Ladesäule", value: "In Planung - Ladesäule" },
//          { label: "Schnellladesäule", value: "In Planung - Schnellladesäule" },
//        ],
//        type: "checkbox",
//      },
//    ],
//    values: ["Ladesäule", "Schnellladesäule"],
//    collections: [
//      "objects5/In Planung/AC",
//      "objects5/In Planung/DC",
//      "objects5/In Planung/MIXED",
//    ],
//  },
//  "in umsetzung": {
//    category: "Ladeinfrastruktur",
//    menu: {
//      label: "In Umsetzung",
//      layer: [
//        { label: "Ladesäule", value: "In Umsetzung - Ladesäule" },
//        {
//          label: "Schnellladesäule",
//          value: "In Umsetzung - Schnellladesäule",
//        },
//      ],
//      type: "checkbox",
//    },
//    values: ["Ladesäule", "Schnellladesäule"],
//    collections: [
//      "objects5/In Umsetzung/AC",
//      "objects5/In Umsetzung/DC",
//      "objects5/In Umsetzung/MIXED",
//    ],
//  },
//};

//const mappedObjects = [
//  {
//    category: "Stempelgeraete",
//    label: "Stempelgeraete",
//    layer: [{ label: "Stempelgeraete", value: "Stempelgerät" }],
//    type: "checkbox",
//  },
//  {
//    category: "Strom",
//    label: "Stationen",
//    layer: [
//      {
//        label: "Stationen",
//        layer: [
//          {
//            label: "Kreis Viersen",
//            layer: [
//              {
//                label: "Ortsnetzstation",
//                value: "Kreis Viersen - Ortsnetzstation",
//              },
//              {
//                label: "Kundenstation",
//                value: "Kreis Viersen - Kundenstation",
//              },
//              {
//                label: "Gemeinschaftsstation",
//                value: "Kreis Viersen - Gemeinschaftsstation",
//              },
//              {
//                label: "MS Schrank",
//                value: "Kreis Viersen - MS Schrank",
//              },
//              {
//                label: "Verteiler SA",
//                value: "Kreis Viersen - Verteiler SA",
//              },
//            ],
//            type: "checkbox",
//          },
//          {
//            label: "Kreis Heinsberg",
//            layer: [
//              {
//                label: "Ortsnetzstation",
//                value: "Kreis Heinsberg - Ortsnetzstation",
//              },
//              {
//                label: "Kundenstation",
//                value: "Kreis Heinsberg - Kundenstation",
//              },
//              {
//                label: "Gemeinschaftsstation",
//                value: "Kreis Heinsberg - Gemeinschaftsstation",
//              },
//              {
//                label: "MS Schrank",
//                value: "Kreis Heinsberg - MS Schrank",
//              },
//              {
//                label: "Verteiler SA",
//                value: "Kreis Heinsberg - Verteiler SA",
//              },
//            ],
//            type: "checkbox",
//          },
//          {
//            label: "Rhein Kreis Neuss",
//            layer: [
//              {
//                label: "Ortsnetzstation",
//                value: "Rhein Kreis Neuss - Ortsnetzstation",
//              },
//              {
//                label: "Kundenstation",
//                value: "Rhein Kreis Neuss - Kundenstation",
//              },
//              {
//                label: "Gemeinschaftsstation",
//                value: "Rhein Kreis Neuss - Gemeinschaftsstation",
//              },
//              {
//                label: "MS Schrank",
//                value: "Rhein Kreis Neuss - MS Schrank",
//              },
//              {
//                label: "Verteiler SA",
//                value: "Rhein Kreis Neuss - Verteiler SA",
//              },
//            ],
//            type: "checkbox",
//          },
//          {
//            label: "Stadt Mönchengladbach",
//            layer: [
//              {
//                label: "Ortsnetzstation",
//                value: "Stadt Mönchengladbach - Ortsnetzstation",
//              },
//              {
//                label: "Kundenstation",
//                value: "Stadt Mönchengladbach - Kundenstation",
//              },
//              {
//                label: "Gemeinschaftsstation",
//                value: "Stadt Mönchengladbach - Gemeinschaftsstation",
//              },
//              {
//                label: "MS Schrank",
//                value: "Stadt Mönchengladbach - MS Schrank",
//              },
//              {
//                label: "Verteiler SA",
//                value: "Stadt Mönchengladbach - Verteiler SA",
//              },
//            ],
//            type: "checkbox",
//          },
//        ],
//        type: "submenu",
//      },
//    ],
//    type: "submenu",
//  },
//  {
//    category: "Strom",
//    label: "Umspannanlagen",
//    layer: [
//      { label: "Kreis Viersen", value: "Kreis Viersen - Umspannanlage" },
//      {
//        label: "Kreis Heinsberg",
//        value: "Kreis Heinsberg - Umspannanlage",
//      },
//      {
//        label: "Rhein Kreis Neuss",
//        value: "Rhein Kreis Neuss - Umspannanlage",
//      },
//      {
//        label: "Stadt Mönchengladbach",
//        value: "Stadt Mönchengladbach - Umspannanlage",
//      },
//    ],
//    type: "checkbox",
//  },
//  {
//    category: "Strom",
//    label: "Kabelverteiler",
//    layer: [
//      {
//        label: "Kreis Viersen",
//        layer: [
//          {
//            label: "Brüggen",
//            value: "Brüggen - NS Kabelverteiler",
//          },
//          {
//            label: "Krefeld",
//            value: "Krefeld - NS Kabelverteiler",
//          },
//          {
//            label: "Niederkrüchten",
//            value: "Niederkrüchten - NS Kabelverteiler",
//          },
//          {
//            label: "Schwalmtal",
//            value: "Schwalmtal - NS Kabelverteiler",
//          },
//          {
//            label: "Viersen",
//            value: "Viersen - NS Kabelverteiler",
//          },
//          {
//            label: "Tönisvorst",
//            value: "Tönisvorst - NS Kabelverteiler",
//          },
//        ],
//        type: "checkbox",
//      },
//      {
//        label: "Kreis Heinsberg",
//        layer: [
//          { label: "Erkelenz", value: "Erkelenz - NS Kabelverteiler" },
//          { label: "Gangelt", value: "Gangelt - NS Kabelverteiler" },
//          {
//            label: "Geilenkirchen",
//            value: "Geilenkirchen - NS Kabelverteiler",
//          },
//          { label: "Heinsberg", value: "Heinsberg - NS Kabelverteiler" },
//          {
//            label: "Hückelhoven",
//            value: "Hückelhoven - NS Kabelverteiler",
//          },
//          { label: "Selfkant", value: "Selfkant - NS Kabelverteiler" },
//          {
//            label: "Übach-Palenberg",
//            value: "Übach-Palenberg - NS Kabelverteiler",
//          },
//          { label: "Waldfeucht", value: "Waldfeucht - NS Kabelverteiler" },
//          { label: "Wassenberg", value: "Wassenberg - NS Kabelverteiler" },
//          { label: "Wegberg", value: "Wegberg - NS Kabelverteiler" },
//        ],
//        type: "checkbox",
//      },
//      {
//        label: "Rhein Kreis Neuss",
//        layer: [
//          {
//            label: "Grevenbroich",
//            value: "Grevenbroich - NS Kabelverteiler",
//          },
//          {
//            label: "Jüchen",
//            value: "Jüchen - NS Kabelverteiler",
//          },
//          {
//            label: "Korschenbroich",
//            value: "Korschenbroich - NS Kabelverteiler",
//          },
//          {
//            label: "Neuss",
//            value: "Neuss - NS Kabelverteiler",
//          },
//        ],
//        type: "checkbox",
//      },
//      {
//        label: "Stadt Mönchengladbach",
//        layer: [
//          {
//            label: "Nord",
//            value: "Mönchengladbach Nord - NS Kabelverteiler",
//          },
//          {
//            label: "Ost",
//            value: "Mönchengladbach Ost - NS Kabelverteiler",
//          },
//          {
//            label: "Süd",
//            value: "Mönchengladbach Süd - NS Kabelverteiler",
//          },
//          {
//            label: "West",
//            value: "Mönchengladbach West - NS Kabelverteiler",
//          },
//        ],
//        type: "checkbox",
//      },
//    ],
//    type: "submenu",
//  },
//  {
//    category: "Strom",
//    label: "Schaltstellen",
//    layer: [
//      { label: "Kreis Viersen", value: "Kreis Viersen - BL Schaltstelle" },
//      {
//        label: "Kreis Heinsberg",
//        value: "Kreis Heinsberg - BL Schaltstelle",
//      },
//      {
//        label: "Rhein Kreis Neuss",
//        value: "Rhein Kreis Neuss - BL Schaltstelle",
//      },
//      {
//        label: "Stadt Mönchengladbach",
//        value: "Stadt Mönchengladbach - BL Schaltstelle",
//      },
//    ],
//    type: "checkbox",
//  },
//  {
//    category: "Gas",
//    label: "Kombianlage",
//    layer: [
//      { label: "Kreis Viersen", value: "Kreis Viersen - Kombianlage" },
//      { label: "Kreis Heinsberg", value: "Kreis Heinsberg - Kombianlage" },
//      {
//        label: "Rhein Kreis Neuss",
//        value: "Rhein Kreis Neuss - Kombianlage",
//      },
//      {
//        label: "Stadt Mönchengladbach",
//        value: "Stadt Mönchengladbach - Kombianlage",
//      },
//    ],
//    type: "checkbox",
//  },
//  {
//    category: "Gas",
//    label: "Erdgastankstelle",
//    layer: [
//      { label: "Kreis Viersen", value: "Kreis Viersen - Erdgastankstelle" },
//      {
//        label: "Kreis Heinsberg",
//        value: "Kreis Heinsberg - Erdgastankstelle",
//      },
//      {
//        label: "Rhein Kreis Neuss",
//        value: "Rhein Kreis Neuss - Erdgastankstelle",
//      },
//      {
//        label: "Stadt Mönchengladbach",
//        value: "Stadt Mönchengladbach - Erdgastankstelle",
//      },
//    ],
//    type: "checkbox",
//  },
//  {
//    category: "Gas",
//    label: "Bezirksregelanlage",
//    layer: [
//      {
//        label: "Kreis Viersen",
//        value: "Kreis Viersen - Bezirksregelanlage",
//      },
//      {
//        label: "Kreis Heinsberg",
//        value: "Kreis Heinsberg - Bezirksregelanlage",
//      },
//      {
//        label: "Rhein Kreis Neuss",
//        value: "Rhein Kreis Neuss - Bezirksregelanlage",
//      },
//      {
//        label: "Stadt Mönchengladbach",
//        value: "Stadt Mönchengladbach - Bezirksregelanlage",
//      },
//    ],
//    type: "checkbox",
//  },
//  {
//    category: "Gas",
//    label: "Kundenanlage",
//    layer: [
//      {
//        label: "Kreis Viersen",
//        value: "Kreis Viersen - Übergabestation",
//      },
//      {
//        label: "Kreis Heinsberg",
//        value: "Kreis Heinsberg - Übergabestation",
//      },
//      {
//        label: "Rhein Kreis Neuss",
//        value: "Rhein Kreis Neuss - Übergabestation",
//      },
//      {
//        label: "Stadt Mönchengladbach",
//        value: "Stadt Mönchengladbach - Übergabestation",
//      },
//    ],
//    type: "checkbox",
//  },
//  {
//    category: "Gas",
//    label: "Übergabestation",
//    layer: [
//      {
//        label: "Kreis Viersen",
//        value: "Kreis Viersen - Übergabestation",
//      },
//      {
//        label: "Kreis Heinsberg",
//        value: "Kreis Heinsberg - Übergabestation",
//      },
//      {
//        label: "Rhein Kreis Neuss",
//        value: "Rhein Kreis Neuss - Übergabestation",
//      },
//      {
//        label: "Stadt Mönchengladbach",
//        value: "Stadt Mönchengladbach - Übergabestation",
//      },
//    ],
//    type: "checkbox",
//  },
//  {
//    category: "Ladeinfrastruktur",
//    label: "In Betrieb",
//    layer: [
//      { label: "Ladesäule", value: "In Betrieb - Ladesäule" },
//      { label: "Schnellladesäule", value: "In Betrieb - Schnellladesäule" },
//    ],
//    type: "checkbox",
//  },
//  {
//    category: "Ladeinfrastruktur",
//    label: "In Planung",
//    layer: [
//      { label: "Ladesäule", value: "In Planung - Ladesäule" },
//      { label: "Schnellladesäule", value: "In Planung - Schnellladesäule" },
//    ],
//    type: "checkbox",
//  },
//  {
//    category: "Ladeinfrastruktur",
//    label: "In Umsetzung",
//    layer: [
//      { label: "Ladesäule", value: "In Umsetzung - Ladesäule" },
//      {
//        label: "Schnellladesäule",
//        value: "In Umsetzung - Schnellladesäule",
//      },
//    ],
//    type: "checkbox",
//  },
//];

const rightStructure = {
  gas: [
    "Bezirksregelanlage",
    "Erdgastankstelle",
    "Kombianlage",
    "Kundenanlage",
    "Übergabestation",
  ],
  stempelgeräte: ["Stempelgeräte"],
  strom: ["Kabelverteiler", "Schaltstellen", "Stationen", "Umspannanlagen"],
  "ladeinfrastruktur - in betrieb": ["In Betrieb"],
  "ladeinfrastruktur - in planung": ["In Planung"],
  "ladeinfrastruktur - in umsetzung": ["In Umsetzung"],
};

const objectStructure = [
  {
    category: "Stempelgeräte",
    label: "Stempelgeräte",
    layer: [{ label: "Stempelgeräte", value: "Stempelgerät" }],
    type: "checkbox",
    firebaseCollection: "objects5/Stempelgeräte/objects",
    submenu: ["Stempelgeräte"],
    right: "Stempelgeräte",
  },
  {
    category: "Strom",
    label: "Stationen",
    layer: [
      {
        label: "Kreis Viersen",
        layer: [
          {
            label: "Ortsnetzstation",
            value: "Kreis Viersen - Ortsnetzstation",
          },
          {
            label: "Kundenstation",
            value: "Kreis Viersen - Kundenstation",
          },
          {
            label: "Gemeinschaftsstation",
            value: "Kreis Viersen - Gemeinschaftsstation",
          },
          {
            label: "MS Schrank",
            value: "Kreis Viersen - MS Schrank",
          },
          {
            label: "Verteiler SA",
            value: "Kreis Viersen - Verteiler SA",
          },
        ],
        type: "checkbox",
      },
      {
        label: "Kreis Heinsberg",
        layer: [
          {
            label: "Ortsnetzstation",
            value: "Kreis Heinsberg - Ortsnetzstation",
          },
          {
            label: "Kundenstation",
            value: "Kreis Heinsberg - Kundenstation",
          },
          {
            label: "Gemeinschaftsstation",
            value: "Kreis Heinsberg - Gemeinschaftsstation",
          },
          {
            label: "MS Schrank",
            value: "Kreis Heinsberg - MS Schrank",
          },
          {
            label: "Verteiler SA",
            value: "Kreis Heinsberg - Verteiler SA",
          },
        ],
        type: "checkbox",
      },
      {
        label: "Rhein Kreis Neuss",
        layer: [
          {
            label: "Ortsnetzstation",
            value: "Rhein Kreis Neuss - Ortsnetzstation",
          },
          {
            label: "Kundenstation",
            value: "Rhein Kreis Neuss - Kundenstation",
          },
          {
            label: "Gemeinschaftsstation",
            value: "Rhein Kreis Neuss - Gemeinschaftsstation",
          },
          {
            label: "MS Schrank",
            value: "Rhein Kreis Neuss - MS Schrank",
          },
          {
            label: "Verteiler SA",
            value: "Rhein Kreis Neuss - Verteiler SA",
          },
        ],
        type: "checkbox",
      },
      {
        label: "Stadt Mönchengladbach",
        layer: [
          {
            label: "Ortsnetzstation",
            value: "Stadt Mönchengladbach - Ortsnetzstation",
          },
          {
            label: "Kundenstation",
            value: "Stadt Mönchengladbach - Kundenstation",
          },
          {
            label: "Gemeinschaftsstation",
            value: "Stadt Mönchengladbach - Gemeinschaftsstation",
          },
          {
            label: "MS Schrank",
            value: "Stadt Mönchengladbach - MS Schrank",
          },
          {
            label: "Verteiler SA",
            value: "Stadt Mönchengladbach - Verteiler SA",
          },
        ],
        type: "checkbox",
      },
    ],
    type: "submenu",
    firebaseCollection: "objects5/Strom/Stationen",
  },
  {
    category: "Strom",
    label: "Umspannanlagen",
    layer: [
      { label: "Kreis Viersen", value: "Kreis Viersen - Umspannanlage" },
      {
        label: "Kreis Heinsberg",
        value: "Kreis Heinsberg - Umspannanlage",
      },
      {
        label: "Rhein Kreis Neuss",
        value: "Rhein Kreis Neuss - Umspannanlage",
      },
      {
        label: "Stadt Mönchengladbach",
        value: "Stadt Mönchengladbach - Umspannanlage",
      },
    ],
    type: "checkbox",
    firebaseCollection: "objects5/Strom/Umspannanlagen",
  },
  {
    category: "Strom",
    label: "Kabelverteiler",
    layer: [
      {
        label: "Kreis Viersen",
        layer: [
          {
            label: "Brüggen",
            value: "Brüggen - NS Kabelverteiler",
          },
          {
            label: "Krefeld",
            value: "Krefeld - NS Kabelverteiler",
          },
          {
            label: "Niederkrüchten",
            value: "Niederkrüchten - NS Kabelverteiler",
          },
          {
            label: "Schwalmtal",
            value: "Schwalmtal - NS Kabelverteiler",
          },
          {
            label: "Viersen",
            value: "Viersen - NS Kabelverteiler",
          },
          {
            label: "Tönisvorst",
            value: "Tönisvorst - NS Kabelverteiler",
          },
        ],
        type: "checkbox",
      },
      {
        label: "Kreis Heinsberg",
        layer: [
          { label: "Erkelenz", value: "Erkelenz - NS Kabelverteiler" },
          { label: "Gangelt", value: "Gangelt - NS Kabelverteiler" },
          {
            label: "Geilenkirchen",
            value: "Geilenkirchen - NS Kabelverteiler",
          },
          { label: "Heinsberg", value: "Heinsberg - NS Kabelverteiler" },
          {
            label: "Hückelhoven",
            value: "Hückelhoven - NS Kabelverteiler",
          },
          { label: "Selfkant", value: "Selfkant - NS Kabelverteiler" },
          {
            label: "Übach-Palenberg",
            value: "Übach-Palenberg - NS Kabelverteiler",
          },
          { label: "Waldfeucht", value: "Waldfeucht - NS Kabelverteiler" },
          { label: "Wassenberg", value: "Wassenberg - NS Kabelverteiler" },
          { label: "Wegberg", value: "Wegberg - NS Kabelverteiler" },
        ],
        type: "checkbox",
      },
      {
        label: "Rhein Kreis Neuss",
        layer: [
          {
            label: "Grevenbroich",
            value: "Grevenbroich - NS Kabelverteiler",
          },
          {
            label: "Jüchen",
            value: "Jüchen - NS Kabelverteiler",
          },
          {
            label: "Korschenbroich",
            value: "Korschenbroich - NS Kabelverteiler",
          },
          {
            label: "Neuss",
            value: "Neuss - NS Kabelverteiler",
          },
        ],
        type: "checkbox",
      },
      {
        label: "Stadt Mönchengladbach",
        layer: [
          {
            label: "Nord",
            value: "Mönchengladbach Nord - NS Kabelverteiler",
          },
          {
            label: "Ost",
            value: "Mönchengladbach Ost - NS Kabelverteiler",
          },
          {
            label: "Süd",
            value: "Mönchengladbach Süd - NS Kabelverteiler",
          },
          {
            label: "West",
            value: "Mönchengladbach West - NS Kabelverteiler",
          },
        ],
        type: "checkbox",
      },
    ],
    type: "submenu",
    firebaseCollection: "objects5/Strom/Kabelverteiler",
  },
  {
    category: "Strom",
    label: "Schaltstellen",
    layer: [
      { label: "Kreis Viersen", value: "Kreis Viersen - BL Schaltstelle" },
      {
        label: "Kreis Heinsberg",
        value: "Kreis Heinsberg - BL Schaltstelle",
      },
      {
        label: "Rhein Kreis Neuss",
        value: "Rhein Kreis Neuss - BL Schaltstelle",
      },
      {
        label: "Stadt Mönchengladbach",
        value: "Stadt Mönchengladbach - BL Schaltstelle",
      },
    ],
    type: "checkbox",
    firebaseCollection: "objects5/Strom/Schaltstellen",
  },
  {
    category: "Gas",
    label: "Kombianlage",
    layer: [
      { label: "Kreis Viersen", value: "Kreis Viersen - Kombianlage" },
      { label: "Kreis Heinsberg", value: "Kreis Heinsberg - Kombianlage" },
      {
        label: "Rhein Kreis Neuss",
        value: "Rhein Kreis Neuss - Kombianlage",
      },
      {
        label: "Stadt Mönchengladbach",
        value: "Stadt Mönchengladbach - Kombianlage",
      },
    ],
    type: "checkbox",
    firebaseCollection: "objects5/Gas/Kombianlage",
  },
  {
    category: "Gas",
    label: "Erdgastankstelle",
    layer: [
      { label: "Kreis Viersen", value: "Kreis Viersen - Erdgastankstelle" },
      {
        label: "Kreis Heinsberg",
        value: "Kreis Heinsberg - Erdgastankstelle",
      },
      {
        label: "Rhein Kreis Neuss",
        value: "Rhein Kreis Neuss - Erdgastankstelle",
      },
      {
        label: "Stadt Mönchengladbach",
        value: "Stadt Mönchengladbach - Erdgastankstelle",
      },
    ],
    type: "checkbox",
    firebaseCollection: "objects5/Gas/Erdgastankstelle",
  },
  {
    category: "Gas",
    label: "Bezirksregelanlage",
    layer: [
      {
        label: "Kreis Viersen",
        value: "Kreis Viersen - Bezirksregelanlage",
      },
      {
        label: "Kreis Heinsberg",
        value: "Kreis Heinsberg - Bezirksregelanlage",
      },
      {
        label: "Rhein Kreis Neuss",
        value: "Rhein Kreis Neuss - Bezirksregelanlage",
      },
      {
        label: "Stadt Mönchengladbach",
        value: "Stadt Mönchengladbach - Bezirksregelanlage",
      },
    ],
    type: "checkbox",
    firebaseCollection: "objects5/Gas/Bezirksregelanlage",
  },
  {
    category: "Gas",
    label: "Kundenanlage",
    layer: [
      {
        label: "Kreis Viersen",
        value: "Kreis Viersen - Übergabestation",
      },
      {
        label: "Kreis Heinsberg",
        value: "Kreis Heinsberg - Übergabestation",
      },
      {
        label: "Rhein Kreis Neuss",
        value: "Rhein Kreis Neuss - Übergabestation",
      },
      {
        label: "Stadt Mönchengladbach",
        value: "Stadt Mönchengladbach - Übergabestation",
      },
    ],
    type: "checkbox",
    firebaseCollection: "objects5/Gas/Kundenanlage",
  },
  {
    category: "Gas",
    label: "Übergabestation",
    layer: [
      {
        label: "Kreis Viersen",
        value: "Kreis Viersen - Übergabestation",
      },
      {
        label: "Kreis Heinsberg",
        value: "Kreis Heinsberg - Übergabestation",
      },
      {
        label: "Rhein Kreis Neuss",
        value: "Rhein Kreis Neuss - Übergabestation",
      },
      {
        label: "Stadt Mönchengladbach",
        value: "Stadt Mönchengladbach - Übergabestation",
      },
    ],
    type: "checkbox",
    firebaseCollection: "objects5/Gas/Übergabestation",
  },
  {
    category: "Ladeinfrastruktur",
    label: "In Betrieb",
    layer: [
      { label: "Ladesäule", value: "In Betrieb - Ladesäule" },
      { label: "Schnellladesäule", value: "In Betrieb - Schnellladesäule" },
    ],
    type: "checkbox",
    firebaseCollection: "objects5/Ladeinfrastruktur/In Betrieb",
  },
  {
    category: "Ladeinfrastruktur",
    label: "In Planung",
    layer: [
      { label: "Ladesäule", value: "In Planung - Ladesäule" },
      { label: "Schnellladesäule", value: "In Planung - Schnellladesäule" },
    ],
    type: "checkbox",
    firebaseCollection: "objects5/Ladeinfrastruktur/In Planung",
    submenu: ["Ladesäule", "Schnellladesäule"],
    right: "Ladeinfrastruktur - In Planung",
  },
  {
    category: "Ladeinfrastruktur",
    label: "In Umsetzung",
    layer: [
      { label: "Ladesäule", value: "In Umsetzung - Ladesäule" },
      {
        label: "Schnellladesäule",
        value: "In Umsetzung - Schnellladesäule",
      },
    ],
    type: "checkbox",
    firebaseCollection: "objects5/Ladeinfrastruktur/In Umsetzung",
    submenu: ["Ladesäule", "Schnellladesäule"],
    right: "Ladeinfrastruktur - In Umsetzung",
  },
];

const dropdownStructure = [
  { main: "Stempelgeräte", sub: ["Stempelgerät"] },
  {
    main: "Ladeinfrastruktur - In Planung",
    sub: ["Ladesäule", "Schnellladesäule"],
  },
  {
    main: "Ladeinfrastruktur - In Umsetzung",
    sub: ["Ladesäule", "Schnellladesäule"],
  },
];

const getFirebaseCollectionsArray = (arr) => {
  if (!Array.isArray(arr)) {
    return [];
  }

  return arr
    .map((value) => rightStructure[value.toLowerCase()])
    ?.flat()
    ?.filter((val) => val)
    .map((value) => {
      return objectStructure.find(
        (object) => object.label.toLowerCase() == value.toLowerCase()
      ).firebaseCollection;
    });
};

//const sortArray = (arr = [], base = "label") => {
//  if (!Array.isArray(arr)) {
//    return [];
//  }
//  return arr.sort((a, b) => {
//    if (a[base] < b[base]) {
//      return -1;
//    }
//    if (a[base] > b[base]) {
//      return 1;
//    }
//    return 0;
//  });
//};

//const getDividedCategories = (arr = []) => {
//  if (!Array.isArray(arr)) {
//    return [];
//  }

//  return mappedObjects
//    .sort((a, b) => {
//      if (a.label > b.label) {
//        return 1;
//      }
//      if (a.label < b.label) {
//        return -1;
//      }
//      return 0;
//    })
//    .filter((category) => {
//      return arr.includes(category.category);
//    })
//    .reduce((mainAcc, mainCur) => {
//      const { category, ...rest } = mainCur;
//      const foundIndex = mainAcc.findIndex((obj) => obj.category == category);
//      if (foundIndex >= 0) {
//        mainAcc[foundIndex].content.push({ ...rest });
//      } else {
//        mainAcc.push({
//          category,
//          content: [{ ...rest }],
//        });
//      }
//      return mainAcc;
//    }, []);
//};

//const getMainCategory = (subCategory = "Stempelgerät") => {
//  const foundCategory = dropdownStructure.find((category) =>
//    category.sub.includes(subCategory)
//  );
//  if (foundCategory) {
//    return foundCategory.main;
//  }
//  return "Unbekannt";
//};

export {
  getHauptKategorie,
  getFirebaseCollectionsArray,
  //  getMainCategory,
  //  getDropdownList,
  rightStructure,
  objectStructure,
  dropdownStructure,
};
