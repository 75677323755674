<template>
  <div class="text-center">
    <v-dialog v-model="$store.state.dialog.dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ $store.state.dialog.title }}
        </v-card-title>

        <v-card-text>
          {{ $store.state.dialog.content }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeDialog"> Schließen </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  methods: {
    closeDialog() {
      this.$store.commit("closeDialog");
    },
  },
};
</script>
