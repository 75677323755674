import Vue from "vue";

const state = {
  isSignedIn: false,
  isAuthenticating: false,
  user: null,
  profile: null,
  claims: {
    permissions: {
      admin: false,
      readPermissions: [],
      writePermissions: [],
    },
    profile: {
      aid: "",
      email: "",
      email_verified: false,
      family_name: "",
      name: "",
    },
  },
  isCookie: false,
};

const getters = {
  //  aid(state) {
  //    return state.profile?.employee?.aid;
  //  },
  aid(state) {
    return state.claims.profile.aid;
  },

  email(state) {
    return state.profile.email;
  },

  readPermissions(state) {
    return state.claims.permissions.readPermissions;
  },

  writePermissions(state) {
    return state.claims.permissions.writePermissions;
  },

  isAdmin() {
    return state.claims.permissions.admin;
  },
};

const mutations = {
  setCookieState(state, cookieState) {
    state.isCookie = cookieState;
  },
  setIsAuthenticating(state, isAuthenticating) {
    state.isAuthenticating = isAuthenticating;
  },

  setUserLogin(state, user) {
    state.user = user;
  },

  setProfile(state, profile) {
    Vue.set(state, "profile", profile);
  },

  setUserLogout(state) {
    state.user = null;
    state.profile = null;
  },

  setUserClaims(state, claims) {
    Vue.set(state, "claims", claims);
  },
};

export default { state, getters, mutations };
