const state = {
  objects: [
    {
      category: "Stempelgeräte",
      label: "Stempelgeräte",
      layer: [{ label: "Stempelgeräte", value: "Stempelgerät" }],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Stempelgeräte/objects",
      submenu: ["Stempelgeräte"],
      right: "Stempelgeräte",
      isWriteAllowed: true,
    },
    {
      category: "Strom",
      label: "Stationen",
      layer: [
        {
          label: "Kreis Viersen",
          layer: [
            {
              label: "Ortsnetzstation",
              value: "Kreis Viersen - Ortsnetzstation",
            },
            {
              label: "Kundenstation",
              value: "Kreis Viersen - Kundenstation",
            },
            {
              label: "Gemeinschaftsstation",
              value: "Kreis Viersen - Gemeinschaftsstation",
            },
            {
              label: "MS Schrank",
              value: "Kreis Viersen - MS Schrank",
            },
            {
              label: "Verteiler SA",
              value: "Kreis Viersen - Verteiler SA",
            },
          ],
          type: "checkbox",
        },
        {
          label: "Kreis Heinsberg",
          layer: [
            {
              label: "Ortsnetzstation",
              value: "Kreis Heinsberg - Ortsnetzstation",
            },
            {
              label: "Kundenstation",
              value: "Kreis Heinsberg - Kundenstation",
            },
            {
              label: "Gemeinschaftsstation",
              value: "Kreis Heinsberg - Gemeinschaftsstation",
            },
            {
              label: "MS Schrank",
              value: "Kreis Heinsberg - MS Schrank",
            },
            {
              label: "Verteiler SA",
              value: "Kreis Heinsberg - Verteiler SA",
            },
          ],
          type: "checkbox",
        },
        {
          label: "Rhein Kreis Neuss",
          layer: [
            {
              label: "Ortsnetzstation",
              value: "Rhein Kreis Neuss - Ortsnetzstation",
            },
            {
              label: "Kundenstation",
              value: "Rhein Kreis Neuss - Kundenstation",
            },
            {
              label: "Gemeinschaftsstation",
              value: "Rhein Kreis Neuss - Gemeinschaftsstation",
            },
            {
              label: "MS Schrank",
              value: "Rhein Kreis Neuss - MS Schrank",
            },
            {
              label: "Verteiler SA",
              value: "Rhein Kreis Neuss - Verteiler SA",
            },
          ],
          type: "checkbox",
        },
        {
          label: "Stadt Mönchengladbach",
          layer: [
            {
              label: "Ortsnetzstation",
              value: "Stadt Mönchengladbach - Ortsnetzstation",
            },
            {
              label: "Kundenstation",
              value: "Stadt Mönchengladbach - Kundenstation",
            },
            {
              label: "Gemeinschaftsstation",
              value: "Stadt Mönchengladbach - Gemeinschaftsstation",
            },
            {
              label: "MS Schrank",
              value: "Stadt Mönchengladbach - MS Schrank",
            },
            {
              label: "Verteiler SA",
              value: "Stadt Mönchengladbach - Verteiler SA",
            },
          ],
          type: "checkbox",
        },
      ],
      type: "submenu",
      firebaseCollectionPath: "objects5/Strom/Stationen",
      right: "Strom",
      isWriteAllowed: false,
    },
    {
      category: "Strom",
      label: "Umspannanlagen",
      layer: [
        { label: "Kreis Viersen", value: "Kreis Viersen - Umspannanlage" },
        {
          label: "Kreis Heinsberg",
          value: "Kreis Heinsberg - Umspannanlage",
        },
        {
          label: "Rhein Kreis Neuss",
          value: "Rhein Kreis Neuss - Umspannanlage",
        },
        {
          label: "Stadt Mönchengladbach",
          value: "Stadt Mönchengladbach - Umspannanlage",
        },
      ],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Strom/Umspannanlagen",
      right: "Strom",
      isWriteAllowed: false,
    },
    {
      category: "Strom",
      label: "Kabelverteiler",
      layer: [
        {
          label: "Kreis Viersen",
          layer: [
            {
              label: "Brüggen",
              value: "Brüggen - NS Kabelverteiler",
            },
            {
              label: "Krefeld",
              value: "Krefeld - NS Kabelverteiler",
            },
            {
              label: "Niederkrüchten",
              value: "Niederkrüchten - NS Kabelverteiler",
            },
            {
              label: "Schwalmtal",
              value: "Schwalmtal - NS Kabelverteiler",
            },
            {
              label: "Viersen",
              value: "Viersen - NS Kabelverteiler",
            },
            {
              label: "Tönisvorst",
              value: "Tönisvorst - NS Kabelverteiler",
            },
          ],
          type: "checkbox",
        },
        {
          label: "Kreis Heinsberg",
          layer: [
            { label: "Erkelenz", value: "Erkelenz - NS Kabelverteiler" },
            { label: "Gangelt", value: "Gangelt - NS Kabelverteiler" },
            {
              label: "Geilenkirchen",
              value: "Geilenkirchen - NS Kabelverteiler",
            },
            { label: "Heinsberg", value: "Heinsberg - NS Kabelverteiler" },
            {
              label: "Hückelhoven",
              value: "Hückelhoven - NS Kabelverteiler",
            },
            { label: "Selfkant", value: "Selfkant - NS Kabelverteiler" },
            {
              label: "Übach-Palenberg",
              value: "Übach-Palenberg - NS Kabelverteiler",
            },
            { label: "Waldfeucht", value: "Waldfeucht - NS Kabelverteiler" },
            { label: "Wassenberg", value: "Wassenberg - NS Kabelverteiler" },
            { label: "Wegberg", value: "Wegberg - NS Kabelverteiler" },
          ],
          type: "checkbox",
        },
        {
          label: "Rhein Kreis Neuss",
          layer: [
            {
              label: "Grevenbroich",
              value: "Grevenbroich - NS Kabelverteiler",
            },
            {
              label: "Jüchen",
              value: "Jüchen - NS Kabelverteiler",
            },
            {
              label: "Korschenbroich",
              value: "Korschenbroich - NS Kabelverteiler",
            },
            {
              label: "Neuss",
              value: "Neuss - NS Kabelverteiler",
            },
          ],
          type: "checkbox",
        },
        {
          label: "Stadt Mönchengladbach",
          layer: [
            {
              label: "Nord",
              value: "Mönchengladbach Nord - NS Kabelverteiler",
            },
            {
              label: "Ost",
              value: "Mönchengladbach Ost - NS Kabelverteiler",
            },
            {
              label: "Süd",
              value: "Mönchengladbach Süd - NS Kabelverteiler",
            },
            {
              label: "West",
              value: "Mönchengladbach West - NS Kabelverteiler",
            },
          ],
          type: "checkbox",
        },
      ],
      type: "submenu",
      firebaseCollectionPath: "objects5/Strom/Kabelverteiler",
      right: "Strom",
      isWriteAllowed: false,
    },
    {
      category: "Strom",
      label: "Schaltstellen",
      layer: [
        { label: "Kreis Viersen", value: "Kreis Viersen - BL Schaltstelle" },
        {
          label: "Kreis Heinsberg",
          value: "Kreis Heinsberg - BL Schaltstelle",
        },
        {
          label: "Rhein Kreis Neuss",
          value: "Rhein Kreis Neuss - BL Schaltstelle",
        },
        {
          label: "Stadt Mönchengladbach",
          value: "Stadt Mönchengladbach - BL Schaltstelle",
        },
      ],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Strom/Schaltstellen",
      right: "Strom",
      isWriteAllowed: false,
    },
    {
      category: "Gas",
      label: "Kombianlage",
      layer: [
        { label: "Kreis Viersen", value: "Kreis Viersen - Kombianlage" },
        { label: "Kreis Heinsberg", value: "Kreis Heinsberg - Kombianlage" },
        {
          label: "Rhein Kreis Neuss",
          value: "Rhein Kreis Neuss - Kombianlage",
        },
        {
          label: "Stadt Mönchengladbach",
          value: "Stadt Mönchengladbach - Kombianlage",
        },
      ],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Gas/Kombianlage",
      right: "Gas",
      isWriteAllowed: false,
    },
    {
      category: "Gas",
      label: "Erdgastankstelle",
      layer: [
        { label: "Kreis Viersen", value: "Kreis Viersen - Erdgastankstelle" },
        {
          label: "Kreis Heinsberg",
          value: "Kreis Heinsberg - Erdgastankstelle",
        },
        {
          label: "Rhein Kreis Neuss",
          value: "Rhein Kreis Neuss - Erdgastankstelle",
        },
        {
          label: "Stadt Mönchengladbach",
          value: "Stadt Mönchengladbach - Erdgastankstelle",
        },
      ],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Gas/Erdgastankstelle",
      right: "Gas",
      isWriteAllowed: false,
    },
    {
      category: "Gas",
      label: "Bezirksregelanlage",
      layer: [
        {
          label: "Kreis Viersen",
          value: "Kreis Viersen - Bezirksregelanlage",
        },
        {
          label: "Kreis Heinsberg",
          value: "Kreis Heinsberg - Bezirksregelanlage",
        },
        {
          label: "Rhein Kreis Neuss",
          value: "Rhein Kreis Neuss - Bezirksregelanlage",
        },
        {
          label: "Stadt Mönchengladbach",
          value: "Stadt Mönchengladbach - Bezirksregelanlage",
        },
      ],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Gas/Bezirksregelanlage",
      right: "Gas",
      isWriteAllowed: false,
    },
    {
      category: "Gas",
      label: "Kundenanlage",
      layer: [
        {
          label: "Kreis Viersen",
          value: "Kreis Viersen - Übergabestation",
        },
        {
          label: "Kreis Heinsberg",
          value: "Kreis Heinsberg - Übergabestation",
        },
        {
          label: "Rhein Kreis Neuss",
          value: "Rhein Kreis Neuss - Übergabestation",
        },
        {
          label: "Stadt Mönchengladbach",
          value: "Stadt Mönchengladbach - Übergabestation",
        },
      ],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Gas/Kundenanlage",
      right: "Gas",
      isWriteAllowed: false,
    },
    {
      category: "Gas",
      label: "Übergabestation",
      layer: [
        {
          label: "Kreis Viersen",
          value: "Kreis Viersen - Übergabestation",
        },
        {
          label: "Kreis Heinsberg",
          value: "Kreis Heinsberg - Übergabestation",
        },
        {
          label: "Rhein Kreis Neuss",
          value: "Rhein Kreis Neuss - Übergabestation",
        },
        {
          label: "Stadt Mönchengladbach",
          value: "Stadt Mönchengladbach - Übergabestation",
        },
      ],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Gas/Übergabestation",
      right: "Gas",
      isWriteAllowed: false,
    },
    {
      category: "Ladeinfrastruktur",
      label: "In Betrieb",
      layer: [
        { label: "Ladesäule", value: "In Betrieb - Ladesäule" },
        { label: "Schnellladesäule", value: "In Betrieb - Schnellladesäule" },
      ],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Ladeinfrastruktur/In Betrieb",
      right: "Ladeinfrastruktur - In Betrieb",
      isWriteAllowed: false,
    },
    {
      category: "Ladeinfrastruktur",
      label: "In Planung",
      layer: [
        { label: "Ladesäule", value: "In Planung - Ladesäule" },
        { label: "Schnellladesäule", value: "In Planung - Schnellladesäule" },
      ],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Ladeinfrastruktur/In Planung",
      submenu: ["Ladesäule", "Schnellladesäule"],
      right: "Ladeinfrastruktur - In Planung",
      isWriteAllowed: true,
    },
    {
      category: "Ladeinfrastruktur",
      label: "In Umsetzung",
      layer: [
        { label: "Ladesäule", value: "In Umsetzung - Ladesäule" },
        {
          label: "Schnellladesäule",
          value: "In Umsetzung - Schnellladesäule",
        },
      ],
      type: "checkbox",
      firebaseCollectionPath: "objects5/Ladeinfrastruktur/In Umsetzung",
      submenu: ["Ladesäule", "Schnellladesäule"],
      right: "Ladeinfrastruktur - In Umsetzung",
      isWriteAllowed: true,
    },
  ],
};

const getters = {
  writePermissionStructure(state, getters, rootState, rootGetters) {
    return state.objects.filter((object) =>
      rootGetters.writePermissions.includes(object.right)
    );
  },

  allReadPermissions(state) {
    return state.objects
      .map((object) => object.right)
      .sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }
        return 0;
      });
  },

  allWritePermissions(state) {
    return state.objects
      .filter((object) => object.isWriteAllowed)
      .map((object) => object.right)
      .sort((a, b) => {
        if (a > b) {
          return 1;
        }
        if (b > a) {
          return -1;
        }
        return 0;
      });
  },
};

export default { state, getters };
