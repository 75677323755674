<template>
  <!-- <v-app>
    <v-main v-if="false" class="text-center pt-8">
      <v-container fill-height fluid style="height: 100%">
        <v-row align="center" justify="center">
          <v-progress-circular
            indeterminate
            color="primary"
            :size="50"
          ></v-progress-circular>
        </v-row>
      </v-container>
    </v-main>
    <v-main v-else>
      <Snackbar />
      <Dialog />
      <router-view id="router-view"></router-view>
    </v-main>
  </v-app> -->
  <v-app>
    <InitialLoading />
    <AppNavigation />
    <Snackbar />
    <Dialog />
    <RoutePlanner />
    <div id="loginnew-dropdown"></div>
    <v-main class="flex">
      <!--<v-container fill-height fluid style="height: 100%" v-if="loading">
        <v-row class="text-center">
          <v-col cols="12">
            <v-progress-circular
              indeterminate
              color="primary"
              size="50"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>-->
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import InitialLoading from "@/components/InitialLoading";
import AppNavigation from "@/components/app/AppNavigation";
import Dialog from "@/components/Dialog";
import Snackbar from "@/components/Snackbar";
import RoutePlanner from "@/components/RoutePlanner";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    InitialLoading,
    Dialog,
    Snackbar,
    AppNavigation,
    RoutePlanner,
  },
  data() {
    return {
      isGettingItems: false,
      isLoading: true,
      connectionType: "",
      bandwidth: "",
    };
  },
  computed: {
    ...mapState("poi", ["restoring"]),
    ready() {
      const condA = !this.$store.state.auth.isAuthenticating;
      const condB = this.$store.state.auth.isCookie;
      const condC = this.$store.state.rights.isLoading;
      return condA && condB && condC;
    },
    loading() {
      return this.$store.state.auth.profile == null;
    },

    isFastConnection() {
      return (
        //!this.$store.state.settings.isListView &&
        window.navigator.onLine &&
        (this.connectionType === "4g" || this.connectionType === "5g") &&
        this.bandwidth >= 8
      );
    },
  },
  created() {
    this.$store.commit("loadDefaultView");
    this.$store.dispatch("poi/restoreRoutes");
  },

  async mounted() {
    if (navigator.connection) {
      await navigator.connection.ready;

      this.connectionType = navigator.connection.effectiveType;
      this.bandwidth = navigator.connection.downlink.toFixed(2);
    } else {
      this.connectionType = "Unknown";
      this.bandwidth = "N/A";
    }
  },
  watch: {
    "$store.state.auth.profile": {
      handler: function (profile) {
        if (
          profile != null &&
          !this.$store.state.rights.isLoading &&
          this.$store.getters.readPermissions
        ) {
          this.isGettingItems = true;
          this.$store.dispatch("poi/getItems");
        }
      },
    },

    "$store.state.rights.isLoading": {
      handler: function (isLoading) {
        if (!isLoading && this.isGettingItems) {
          this.$store.dispatch("poi/getItems");
        }
      },
    },

    "$store.state.poi.loading": {
      handler: function (loading) {
        if (!loading && this.isGettingItems) {
          console.log(
            "SELECTEDFILTER",
            this.$store.state.categories.selectedFilterValue
          );
          this.$store.dispatch(
            "poi/findItems",
            this.$store.state.categories.selectedFilterValue
          );
          //  console.log("READRIGHTS", this.$store.getters.getUserReadRights);
        }
      },
    },

    isFastConnection: {
      handler(val) {
        if (val) {
          this.$store.dispatch("poi/clearCache");
        }
      },
    },
    //ready(val) {
    //  console.log("ready", val);
    //  if (val) {
    //    this.$store.dispatch("poi/getItems");
    //  }
    //},
  },
};
</script>

<style>
.app-container {
  width: 100%;
  max-width: 1000px !important;
  margin: 0 auto;
}

#loginnew-dropdown .login-new-avatar span {
  display: none;
}

#loginnew-dropdown {
  position: absolute;
  z-index: 5;
  right: 1rem;
  top: 1.6rem;
  right: 0.6rem;
}

.isHidden {
  display: none !important;
}

.login-new-avatar span {
  display: none;
}

header.v-app-bar {
  contain: unset;
  transform: none !important;
  overflow: visible;
}
</style>
