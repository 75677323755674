<template>
<div>

</div>
  <!--<div v-if="initialLoad" id="initial-fetch">
    <v-container fill-height fluid style="height: 100%">
      <v-col align="center" justify="center">
        <v-progress-circular
          indeterminate
          color="primary"
          :size="40"
        ></v-progress-circular>
        <div class="mt-4">Daten werden aktualisiert. Bitte warten.</div>
      </v-col>
    </v-container>
  </div>-->
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("poi", ["initialLoad"]),
  },

  watch:{
    initialLoad:{
        handler:function(v) {
            if(v && this.$store.state.auth.profile?.employee.secureLogin) {
                //this.$store.commit('showDialog',{
                //    title: 'Aktualisierung der Daten',
                //    content: 'Daten werden im Hintergrund aktualisiert. Bitte warten.',
                //    status: 'success'
                //    })
            }
        },
        immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
#initial-fetch {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8);
}
</style>
