<template>
  <v-snackbar
    v-model="$store.state.snackbar.snackbar"
    :color="$store.state.snackbar.color"
  >
    {{ $store.state.snackbar.message }}
    <template v-slot:action="{ attrs }">
      <v-btn text v-bind="attrs" @click="closeSnackbar"> Schließen </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  methods: {
    closeSnackbar() {
      this.$store.commit("closeSnackbar");
    },
  },
};
</script>
