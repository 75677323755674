import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import {
  getFirestore,
  CACHE_SIZE_UNLIMITED,
  //  enableIndexedDbPersistence,
} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
};

const app = firebase.initializeApp(firebaseConfig);
const firestore = app.firestore();
const auth = app.auth();
const functions = app.functions("europe-west1");

const db = getFirestore(app);
firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    console.log("error enabling indexedDb", err);
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });
//enableIndexedDbPersistence(db).catch((err) => {
//  console.log("error enabling indexedDb", err);
//  if (err.code == "failed-precondition") {
//    console.log("failed precondition");
//    // Multiple tabs open, persistence can only be enabled
//    // in one tab at a a time.
//    // ...
//  } else if (err.code == "unimplemented") {
//    console.log("unimplemented");
//    // The current browser does not support all of the
//    // features required to enable persistence
//    // ...
//  }
//});

export { db, firestore, auth, functions };
