import Vue from "vue";
// import axios from 'axios';
import { collection, getDocs, query, where } from "firebase/firestore";

import ls from "../helper/localStorageService";
import idbs from "../helper/indexedDBService";
import { db } from "@/firebase";

const state = {
    isLoading: false,
    objects: [],
    dataFields: ["objects"],
};

const mutations = {
    setState(state, { field, data }) {
        Vue.set(state, field, data);
    },

    addObject(state, newObject) {
        state.objects.push(newObject);
    },
};

const actions = {
    checkStorage() {
        // state.dataFields.forEach(async (field) => {
        //     try {
        //         let data = await idbs.checkStorage(field);
        //         // IndexedDB did not find the data, try localStorage
        //         if (data === undefined) data = ls.checkStorage(field);
        //         // LocalStorage did not find the data, reset it
        //         if (data === null) data = [];
        //         if (data.length == 0) {
        //             await dispatch("initAllObjectsOffline");
        //         }
        //         commit("setState", { field, data });
        //     } catch (e) {
        //         commit("showDialog", {
        //             title: "ERROR",
        //             content: `Fehler ist aufgetreten, Fehlermeldung: ${e.message}`,
        //             status: "error",
        //         });
        //         // The value in storage was invalid or corrupt so just set it to blank
        //         commit("setState", { field, data: [] });
        //     }
        // });
    },

    async initAllObjectsOffline({
        state,
        rootGetters,
        dispatch,
        rootState,
        commit,
    }) {
        state.isLoading = true;
        try {
            await dispatch("attachIconsOnSnapshot", null, { root: true });

            const q = query(
                collection(db, "objects3"),
                where("mainCategory", "in", rootGetters.getUserReadRights)
            );
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                const objType = doc.data().objectType;
                let type = "";
                if (objType.indexOf("-") > 0) {
                    type = objType.split(" - ")[1];
                } else {
                    type = objType;
                }
                const icon = rootState.icons.icons.find(
                    (icon) => icon.id == type
                );
                let url;
                if (icon) {
                    url = icon.iconURL;
                } else {
                    // Default icon
                    url =
                        "https://firebasestorage.googleapis.com/v0/b/newmaps-dev-d3eec.appspot.com/o/icons%2Fdefault.png?alt=media&token=a093fc94-797f-41ac-b6b0-02db7f5377dd";
                }
                state.objects.push({ ...doc.data(), docid: doc.id, icon: url });
            });
            await dispatch("saveObjects");

            // const raw = {
            //     "apikey": "LGUzMjA5ZGpwa3Btw7bDpGxtLHAwc2QwMjN1cHNkcDNuaw==",
            //     "set": "gisDaten",
            //     "objects": state.objects
            // };
            // await axios.post("https://mapp.new.de/", raw, {
            //     headers: {
            //         "User-Agent": "MAPP",
            //         "Content-Type": "application/json"
            //     }
            // })
        } catch (err) {
            commit("showDialog", {
                title: "ERROR",
                content: `Fehler is aufgetreten, Fehlermeldung: ${err.message} `,
                status: "error",
            });
            console.error(err.message);
        }
        await dispatch("loadDefaultFilterValue", null, { root: true });
        state.isLoading = false;
    },

    async saveObjects({ state, commit }) {
        try {
            await Promise.all(
                state.dataFields.map((field) =>
                    idbs.saveToStorage(field, state[field])
                )
            );
        } catch (err) {
            commit("showDialog", {
                title: "ERROR",
                content: `Fehler is aufgetreten, Fehlermeldung: ${err.message} `,
                status: "error",
            });
            state.dataFields.forEach((field) =>
                ls.saveToStorage(field, state[field])
            );
        }
    },

    async clearCache({ state, commit }) {
        try {
            await idbs.deleteDB();
        } catch (e) {
            commit("showDialog", {
                title: "ERROR",
                content: `Fehler ist aufgetreten, Fehlermeldung: ${e.message}`,
                status: "error",
            });
        }

        state.dataFields.forEach((field) => {
            try {
                commit("setState", { field, data: [] });
            } catch (e) {
                commit("showDialog", {
                    title: "ERROR",
                    content: `Fehler ist aufgetreten, Fehlermeldung: ${e.message}`,
                    status: "error",
                });
                // The value in storage was invalid or corrupt so just set it to blank
                commit("setState", { field, data: [] });
            }
        });
    },
};

export default { state, mutations, actions };
