import Vue from "vue";

import { auth, functions } from "./firebase";
import { getIdTokenResult } from "firebase/auth";
import store from "./store";
import router from "./router";

const configureVisibleDropdownButtons = () => {
  if (store.getters.isAdmin) {
    loginNew.dropdown.layout().item("btn-manage-user").show();
  }
  if (store.getters.writePermissions.length > 0) {
    loginNew.dropdown.layout().item("btn-manage").show();
  }
  if (store.getters.readPermissions.length > 0) {
    loginNew.dropdown.layout().item("btn-clear-cache").show();
  }
  loginNew.dropdown.layout().item("btn-impressum").show();
};

if (navigator.onLine) {
  loginNew.init(
    "new-maps",
    location.protocol + "//" + location.host + "/login",
    [],
    "aid aid_secure profile usercentrics openid email"
  );

  loginNew.dropdown
    .layout()
    .register([
      {
        id: "btn-manage-user",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "Benutzer verwalten",
        icon: "mdi-account-cog-outline",
        hidden: true,
        callback: () => {
          router.push("/manage-user").catch(() => {});
        },
      },
      {
        id: "btn-manage",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "Objekte verwalten",
        icon: "mdi-cog-outline",
        hidden: true,
        callback: () => {
          router.push("/manage").catch(() => {});
        },
      },
      {
        id: "btn-clear-cache",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "Cache leeren",
        icon: "mdi-cached",
        hidden: true,
        callback: () => {
          store.dispatch("poi/clearCache");
        },
      },
      {
        id: "btn-impressum",
        type: loginNew.dropdown.TYPE_BUTTON,
        title: "Impressum",
        icon: "mdi-format-section",
        hidden: true,
        callback: () => {
          //window.location = "https://www.new.de/impressum";
          window.open("https://www.new.de/impressum", "_blank").focus();
        },
      },
    ])
    .show();

  loginNew.onAuthStateChanged(async () => {
    store.commit("setIsAuthenticating", true);
    //await store.dispatch("attachUserRightsOnSnapshot");

    if (loginNew.isSignedIn()) {
      //  const profile = loginNew.getProfile();
      //  const userCredential = profile.employee?.aid
      //    ? profile.employee.aid
      //    : profile.email;
      //  if (profile.employee.secureLogin == true && store.getters.isAdmin) {
      //    loginNew.dropdown.addButton(
      //      loginNew.SIGNED_IN,
      //      "my-button-id",
      //      "Objekte Verwalten",
      //      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACOElEQVRIS7WVTW7TUBSFvxfbTFNW0HQFuCugnaEYibACEqmuxCxZAWUFYYZUD5qugFTCEbOGFWBW0GYFlCm289D1s6l/EtVFTSaWnl/uufecc48VO/6pHdenFUCANwamtWYmPuGnhxpsCzAH3tSKXfqEwycC6C9BvQR1bArqa9DffRZHjwYIyIppBTML5yolnmrIOrVxnsszIf4lz/zOJObPQKHeyZlPmDdhoCsUndMfKtTFlq7+URLgzYCsYP2n0aNTFvK+CnDBYC8hvgH2gEsNroIXQkXCevieb7flYp951bPpzIQ6DT8VRAKq4NbCORwxv6tMEPD6DPQHuXBCeCAvz+m7pywiKWbRmXZQrpyv0VHKeiKgxR05D/CkiX1QH32+nlUAavRENs6xdGE6tX7kk5WHuEtIDwUkn/4ayBoo01TXwFUoseQ+cOUTDgK83KKGKiO2oaZ5h5VGD2TqhgbFQUFVMWaAJ1x2E9KDQod8qpuCzvp/ymM2Fm0bgFi0EK4AAFY+Ya81gAjWQX3R0BMnyabeU8QyIR0ZiiyxsixZhUaZaI1+u5GisshiOwfnqCSycNqtef53QuoWIsfES2PrLSI/ZFMbS4ItcwkQJaTjR9k0t5r4uCsRIIuWF8yo2bxohiqhBljmkbKycdzGouWLtTUqBPSEMNPgv6Li3qYSdsIjM4dn84RYqMlypx52YgQbZ2zCzgRiPWFbfg+eMK6b+ZhRsusPzo4/mZumanvWSoO2xTbd+wu/mh0oze0eTQAAAABJRU5ErkJggg==",
      //      () => {
      //        router.push("/manage");
      //      }
      //    );
      //  }
      //  loginNew.dropdown.addButton(
      //    loginNew.SIGNED_IN,
      //    "clear-cache-button",
      //    "Cache leeren",
      //    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACOElEQVRIS7WVTW7TUBSFvxfbTFNW0HQFuCugnaEYibACEqmuxCxZAWUFYYZUD5qugFTCEbOGFWBW0GYFlCm289D1s6l/EtVFTSaWnl/uufecc48VO/6pHdenFUCANwamtWYmPuGnhxpsCzAH3tSKXfqEwycC6C9BvQR1bArqa9DffRZHjwYIyIppBTML5yolnmrIOrVxnsszIf4lz/zOJObPQKHeyZlPmDdhoCsUndMfKtTFlq7+URLgzYCsYP2n0aNTFvK+CnDBYC8hvgH2gEsNroIXQkXCevieb7flYp951bPpzIQ6DT8VRAKq4NbCORwxv6tMEPD6DPQHuXBCeCAvz+m7pywiKWbRmXZQrpyv0VHKeiKgxR05D/CkiX1QH32+nlUAavRENs6xdGE6tX7kk5WHuEtIDwUkn/4ayBoo01TXwFUoseQ+cOUTDgK83KKGKiO2oaZ5h5VGD2TqhgbFQUFVMWaAJ1x2E9KDQod8qpuCzvp/ymM2Fm0bgFi0EK4AAFY+Ya81gAjWQX3R0BMnyabeU8QyIR0ZiiyxsixZhUaZaI1+u5GisshiOwfnqCSycNqtef53QuoWIsfES2PrLSI/ZFMbS4ItcwkQJaTjR9k0t5r4uCsRIIuWF8yo2bxohiqhBljmkbKycdzGouWLtTUqBPSEMNPgv6Li3qYSdsIjM4dn84RYqMlypx52YgQbZ2zCzgRiPWFbfg+eMK6b+ZhRsusPzo4/mZumanvWSoO2xTbd+wu/mh0oze0eTQAAAABJRU5ErkJggg==",
      //    () => {
      //      store.dispatch("poi/clearCache");
      //    }
      //  );

      //  loginNew.dropdown.addButton(
      //    loginNew.SIGNED_IN,
      //    "impressum",
      //    "Impressum",
      //    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAd1JREFUSEu1lUFO21AQhr9x4m5JT9DcgOQEDbsqoSKcgCDFlbqCGxBuEFaVEiSSEzSoJGKHuUFuQHuC0h0SkEGDjXADfi8kYiRLljzv/TP//89YeOeQd76fhQD6bNaU2V6AVBTKAr9n6DRARgXC011G13mFegF61FuCnCQX6CUEMcxqIGWgVCQsLw1wQrN0x+1f4J+itW9MptlKrbOIs9hFs7ODY742ldlPqzxiUltGLyeAVQh6YZwXCKsuKlbQoDEVWDcQoOMTdR7I2UGPeiUV+CPw6emwwECRoY9/y/cAPFZvTqnccFMKCfYVaQFrKVhcJNxeykXP/OtRm8l+tvXUup20q2nEuPpmDZ4dxDBibFX/F6mFTZc1kI08unIp+sGXcpHCVTpgnYjJ4TxIn8YI2BKC7Ta/7P1F+ETOTDG2DgZ33B9959wqp0fiMEWr80OYMYR7fBIn0QX5nMmMQWMQ0+E0Ytx8swbzB4yyly7SyyIfmku5yNVXn80O6EGSky+wdw7cII0BsANyGHFmVL0a3nWdd/CYeleRvZUAzOv33G61GQ+zQOkMmIVLK1GU4dosav8CeyrA0+p+dQizxXgpStYCrfQPZgvvTwIk3ZWXnXtCFvvq7WCxa/KzHgC7NqQZG4KZMgAAAABJRU5ErkJggg==",
      //    () => {
      //      window.location = "https://www.new.de/impressum";
      //    }
      //  );
      //if (profile.employee.secureLogin == true) {
      // Mitarbeiter hat sich mit einem Microsoft-Mitarbeiterkonto angemeldet
      if (navigator.onLine) {
        if (
          //  auth.currentUser &&
          //  auth.currentUser.uid == loginNew.getProfile().sub

          auth.currentUser?.uid == loginNew.getProfile().sub
        ) {
          const tokenResult = await getIdTokenResult(auth.currentUser, true);
          console.log("TOKENRESULT IF", tokenResult);
          store.commit("setProfile", loginNew.getProfile());
          store.commit("setUserLogin", auth.currentUser.uid);
          store.commit("setUserClaims", tokenResult.claims);
          store.commit("setIsAuthenticating", false);
          configureVisibleDropdownButtons();
        } else {
          const getAuthToken = functions.httpsCallable("getAuthToken");
          const response = await getAuthToken({ token: loginNew.getIdToken() });
          if (response.data.response === "OK") {
            await auth.signInWithCustomToken(response.data.token);
            const tokenResult = await getIdTokenResult(auth.currentUser, true);
            console.log("TOKENRESULT ELSE", tokenResult);
            store.commit("setProfile", loginNew.getProfile());
            store.commit("setUserLogin", auth.currentUser.uid);
            store.commit("setUserClaims", tokenResult.claims);
            store.commit("setIsAuthenticating", false);
            configureVisibleDropdownButtons();
          } else {
            router.push("/login").catch((err) => {
              console.log(err);
            });
            store.commit("setIsAuthenticating", false);
          }
        }
      } else {
        store.commit("setIsAuthenticating", false);
      }
      // } else {
      //   // Mitarbeiter hat sich mit einem Mitarbeiter-E-Mail-Konto oder einem privatem Konto angemeldet
      //   store.dispatch("poi/resetItems");
      //   store.commit("showDialog", { title:'Error', content: "Bitte melden Sie sich mit Ihrem Mitarbeiterkonto an." });
      //   store.commit('setIsAuthenticating', false)
      // }
    } else {
      store.commit("setIsAuthenticating", false);
      if (auth.currentUser) {
        auth
          .signOut()
          .then(() => {
            store.dispatch("poi/resetItems");
            store.commit("setUserLogout");
            console.log("signout successful");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  });

  loginNew.onCookieStateChanged(() => {
    store.commit("setCookieState", false);
    Vue.nextTick(() => {
      store.commit("setCookieState", true);
    });
  });
}
