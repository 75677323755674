import { openDb, deleteDb, } from 'idb'

const dbPromise = () => {
  if (!('indexedDB' in window)) {
    throw new Error('Browser does not support IndexedDB')
  }

  return openDb('objects', 1, upgradeDb => {
    if (!upgradeDb.objectStoreNames.contains('objects')) {
      upgradeDb.createObjectStore('objects')
    }
  })
}

const deleteDB = () => {
  return deleteDb('objects')
}

const checkStorage = async storeName => {
  return dbPromise()
    .then(db => {
      const tx = db.transaction(storeName, 'readonly')
      const store = tx.objectStore(storeName)
      const returnedValue = store.get(storeName)

      return returnedValue
    })
    .catch(error => {
      console.error('ERRORCHECKSTORAGE', error.message)
      return error
    })
}

const saveToStorage = async (storeName, tasks) => {
  return dbPromise()
    .then(db => {
      const tx = db.transaction(storeName, 'readwrite')
      const store = tx.objectStore(storeName)

      store.put(tasks, storeName)

      return tx.complete
    })
    .catch(error => {
      return error
    })
}

export default {
  checkStorage,
  saveToStorage,
  deleteDB
}
