const state = {
    type: 'roadmap'
}

const mutations = {
    setMapType(state, type) {
        state.type = type
    }
}

export default { state, mutations }
