const convertToCamelCase = (str) => {
  // converting all characters to lowercase
  let ans = str.toLowerCase();

  // Returning string to camelcase
  return ans
    .split(" ")
    .reduce((s, c) => s + (c.charAt(0).toUpperCase() + c.slice(1)))
    .replaceAll("-", "");
};

const state = {
  values: {
    inBetrieb: 0,
    inPlanung: 0,
    inUmsetzung: 0,
  },
};

const actions = {
  setRadius({ state }, payload) {
    state.values[convertToCamelCase(payload.category)] = parseInt(
      payload.radius
    );
  },
};

export default { state, actions };
