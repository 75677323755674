import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

import auth from "./modules/auth";
import snackbar from "./modules/snackbar";
import dialog from "./modules/dialog";
import objects from "./modules/objects";
import map from "./modules/map";
import rights from "./modules/rights";
import categories from "./modules/categories";
import icons from "./modules/icons";
import localObjects from "./modules/localObjects";
import settings from "./modules/settings";
import poi from "./modules/poi";
import radius from "./modules/radius";
import structure from "./modules/structure";
import users from "./modules/users";
import mappUsers from "./modules/mappUsers";

export default new Vuex.Store({
  modules: {
    auth,
    snackbar,
    dialog,
    objects,
    map,
    rights,
    categories,
    icons,
    localObjects,
    settings,
    poi,
    radius,
    structure,
    users,
    mappUsers,
  },
});
