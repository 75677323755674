import { collection, onSnapshot, query } from "firebase/firestore";

import { db } from '@/firebase';

const state = {
    icons: [],
    firestoreListener: null,
    isLoading: false,
}

const actions = {
    async attachIconsOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.icons);
                return;
            }
            // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            // objectTypes.forEach(type => {
            // for (const type of objectTypes) {
            // console.log(objectTypes)

            state.isLoading = true;

            const q = query(collection(db, 'config', 'icons', 'icons'));
            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {

                    state.icons = []
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.icons.push({ ...doc.data(), id: doc.id });
                        }
                    });
                    state.isLoading = false;
                    resolve(state.icons);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
            // }
            // });

        })
    },
}

export default { state, actions }
